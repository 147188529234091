import React from 'react'
import { useEffect } from 'react'

import { useState } from 'react'

import { useSelector } from 'react-redux'
import { ShareButton } from './Helpers/ShareButton'
import Slider from './Slider'
export const DonationInformation = (props) => {
  const [images, setImages] = useState([])

  const lang = useSelector((state) => state.language.lang)
  console.log(lang)
  useEffect(() => {
    if (!props?.donation) {
      return
    }
    console.log(props.donation?.images)
    const tmpImages = props.donation?.images
      .filter((image) => image.lang == lang || image.lang === 'all')
      .map((img) => img.image)
    setImages(tmpImages)
    console.log('iamges', tmpImages)
  }, [props.donation])
  return (
    <div>
      <div className="text-center card">
        <Slider images={images} />
        <div className="mb-2">
          <ShareButton />
        </div>
        <div
          className="text-justify card-body"
          dangerouslySetInnerHTML={{
            __html: props.donation?.information.replaceAll(
              /\n/g,
              '<br/>',
            ),
          }}
        >
          {}
        </div>
      </div>
    </div>
  )
}
