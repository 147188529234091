import React from 'react'
import { Modal } from 'react-bootstrap'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    PocketShareButton,
    InstapaperShareButton,
    HatenaShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,
    PocketIcon,
    InstapaperIcon,
    HatenaIcon,
} from 'react-share'

export const SocialShare = (props) => {
    const shareUrl = window.location.href
    const title = 'share'
    return (
        <Modal show={props.onShow} onHide={props.onHide}>
            <Modal.Header>Share</Modal.Header>
            <Modal.Body>
                <div className="d-inline-flex text-center mt-3">
                    <div className="h-space-2">
                        <FacebookShareButton
                            url={shareUrl}
                            quote={title}
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>

                        {/* <div className="flex">
                    <FacebookMessengerShareButton
                        url={shareUrl}
                        appId="521270401588372"
                    >
                        <FacebookMessengerIcon size={32} round />
                    </FacebookMessengerShareButton>
                </div> */}

                        <TwitterShareButton
                            url={shareUrl}
                            title={title}
                            className="m-2"
                        >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>

                        <TelegramShareButton
                            url={shareUrl}
                            title={title}
                            className="m-2"
                        >
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>

                        <WhatsappShareButton
                            url={shareUrl}
                            title={title}
                            separator=":: "
                            className="m-2"
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>

                        <LinkedinShareButton
                            className="m-2"
                            url={shareUrl}
                        >
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>

                        <RedditShareButton
                            url={shareUrl}
                            title={title}
                            className="m-2"
                            windowWidth={660}
                            windowHeight={460}
                        >
                            <RedditIcon size={32} round />
                        </RedditShareButton>

                        <TumblrShareButton
                            url={shareUrl}
                            title={title}
                            className="m-2"
                        >
                            <TumblrIcon size={32} round />
                        </TumblrShareButton>

                        <LivejournalShareButton
                            url={shareUrl}
                            title={title}
                            className="m-2"
                            description={shareUrl}
                        >
                            <LivejournalIcon size={32} round />
                        </LivejournalShareButton>

                        <MailruShareButton
                            url={shareUrl}
                            title={title}
                            className="m-2"
                        >
                            <MailruIcon size={32} round />
                        </MailruShareButton>

                        <EmailShareButton
                            url={shareUrl}
                            className="m-2"
                            subject={title}
                            body="body"
                        >
                            <EmailIcon size={32} round />
                        </EmailShareButton>

                        <ViberShareButton
                            url={shareUrl}
                            className="m-2"
                            title={title}
                        >
                            <ViberIcon size={32} round />
                        </ViberShareButton>

                        <WorkplaceShareButton
                            url={shareUrl}
                            className="m-2"
                            quote={title}
                        >
                            <WorkplaceIcon size={32} round />
                        </WorkplaceShareButton>

                        <LineShareButton
                            url={shareUrl}
                            title={title}
                            className="m-2"
                        >
                            <LineIcon size={32} round />
                        </LineShareButton>

                        <PocketShareButton
                            url={shareUrl}
                            className="m-2"
                            title={title}
                        >
                            <PocketIcon size={32} round />
                        </PocketShareButton>

                        <InstapaperShareButton
                            url={shareUrl}
                            title={title}
                            className="m-2"
                        >
                            <InstapaperIcon size={32} round />
                        </InstapaperShareButton>

                        <HatenaShareButton
                            url={shareUrl}
                            title={title}
                            windowWidth={660}
                            windowHeight={460}
                            className="m-2"
                        >
                            <HatenaIcon size={32} round />
                        </HatenaShareButton>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-secondary"
                    onClick={props.onHide}
                >
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}
