import React from 'react'
import Flags from 'country-flag-icons/react/3x2'
import { Tip } from './Helpers/Tip'
export const DonationHeader = (props) => {
  return (
    <div className="navbar shadow-sm p-3 bg-white rounded ">
      <div className="justify-content-start">
        <img
          src="/img/newheader.jpg"
          className="img-fluit d-inline-flex"
          // style={{ height: '180px' }}
        />
        <h3 className=" text-muted mt-2 d-inline-flex ml-3">
          {/* {props.title} */}
        </h3>
      </div>

      <div className="justify-content-end">
        <a href="#">
          <Flags.GB
            id="tipGB"
            title="English"
            style={{ height: 32 }}
            className="shadow"
            onClick={() => props.onChangeLanguage('en')}
          />
        </a>
        <a href="#">
          <Flags.TR
            title="English"
            style={{ height: 32, marginLeft: '8px' }}
            className="shadow"
            onClick={() => props.onChangeLanguage('tr')}
          />
        </a>
      </div>
    </div>
  )
}
