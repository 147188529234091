import React, { useState } from 'react'
import { InputError } from './InputError'
import api from './Api/Api'
import { Loader } from './Loader'
import { API_TOKEN } from '../config/default.json'
import { useHistory } from 'react-router-dom'
import { setLoggedIn } from '../redux/loggedInSlice'
import { useDispatch } from 'react-redux'
export const Register = (props) => {
    let history = useHistory()
    let dispatch = useDispatch()
    const redirect = props.redirect ? props.redirect : '/dashboard'
    const [data, setData] = useState(() => {
        return {
            name: '',
            email: '',
            password: '',
            phone: '',
        }
    })
    const [formError, setFormError] = useState()
    const [emailError, setEmailError] = useState()
    const [nameError, setNameError] = useState()
    const [passwordError, setPasswordError] = useState()
    const [phoneError, setPhoneError] = useState()
    const [loading, setLoading] = useState()
    const [phone, setPhone] = useState()

    const handleChange = (e) => {
        let tmpData = data
        tmpData[e.target.name] = e.target.value
        setData(tmpData)
    }

    const post = async () => {
        setEmailError('')
        setPasswordError('')
        setNameError('')
        setLoading(true)
        try {
            const response = await api.post('users', data)
            localStorage.setItem(API_TOKEN, response.data.api_token)
            history.push(redirect)
            setLoading(false)
            dispatch(setLoggedIn(true))
        } catch (error) {
            const errorResponse = error.response
            if (errorResponse.status === 422) {
                setFormError(errorResponse.data.message)
                if (errorResponse.data.errors['email']) {
                    setEmailError(
                        errorResponse.data.errors['email'][0],
                    )
                }
                if (errorResponse.data.errors['name']) {
                    setNameError(errorResponse.data.errors['name'][0])
                }
                if (errorResponse.data.errors['password']) {
                    setPasswordError(
                        errorResponse.data.errors['password'][0],
                    )
                }
                if (errorResponse.data.errors['phone']) {
                    setPhoneError(
                        errorResponse.data.errors['phone'][0],
                    )
                }
            }
            setLoading(false)
        }
    }

    return (
        <div className="box">
            <h2 className="text-uppercase">New account</h2>
            <p className="lead">Not our registered customer yet?</p>

            <hr />
            {loading && <Loader />}
            {formError && <InputError error={formError} />}
            <div className="form-group">
                <label htmlFor="name-register">Name</label>
                <input
                    id="name-register"
                    name="name"
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                />
                {nameError && <InputError error={nameError} />}
            </div>
            <div className="form-group">
                <label htmlFor="email-register">Phone</label>
                <input
                    id="phone-register"
                    type="text"
                    name="phone"
                    className="form-control"
                    onChange={handleChange}
                />
                {phoneError && <InputError error={phoneError} />}
            </div>
            <div className="form-group">
                <label htmlFor="email-register">Email</label>
                <input
                    id="email-register"
                    type="text"
                    name="email"
                    className="form-control"
                    onChange={handleChange}
                />
                {emailError && <InputError error={emailError} />}
            </div>
            <div className="form-group">
                <label htmlFor="password-register">Password</label>
                <input
                    id="password-register"
                    type="password"
                    name="password"
                    className="form-control"
                    onChange={handleChange}
                />
                {passwordError && (
                    <InputError error={passwordError} />
                )}
            </div>
            <div className="text-center">
                <button
                    type="submit"
                    className="btn btn-template-outlined"
                    onClick={() => {
                        setFormError('')
                        post()
                    }}
                >
                    <i className="fa fa-user-md"></i> Register
                </button>
            </div>
        </div>
    )
}
