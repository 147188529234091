import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
export const SkuItem = (props) => {
  const euro = useSelector((state) => state.currencies.euro)
  const { t } = useTranslation()
  const sku = props.sku
  const [quantity, setQuantity] = useState(() => 1)
  return (
    <div className="text-left d-flex mb-4 justify-content-between align-items-center">
      <span className="text-capitalize text-bold text-left w-100">
        {sku.name}
        <div className="text-muted">
          {sku.price} &#8378;({t('approx')} &euro;
          {(sku.price / euro).toFixed(2)})
        </div>
      </span>{' '}
      <div className=" text-end mr-4 w-25">
        <input
          type="number"
          value={quantity}
          disabled={props.disabled}
          className="form-control me-1"
          placeholder={t('quantity')}
          min="1"
          onChange={(e) => setQuantity(e.target.value)}
        />
      </div>
      <div className="w-25 text-end ms-1">
        <button
          className="btn btn-primary w-100"
          onClick={() => props.onAddToCardClick(quantity, sku)}
          disabled={props.disabled}
        >
          {props.disabled ? t('not_available') : t('buy')}
        </button>
      </div>
    </div>
  )
}
