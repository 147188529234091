import React, { useState, useEffect } from 'react'
import { Loader } from './Loader'
import { ErrorTitle } from './ErrorTitle'
import api from './Api/Api'
import { InputError } from './InputError'
import { YesNoModal } from './YesNoModal'
import { useHistory } from 'react-router-dom'
import { SuccessMessage } from './SuccessMessage'
import { getItem } from './Helpers/LocalStorage'

export const AddressBook = () => {
    let history = useHistory()

    const [success, setSuccess] = useState()
    const [modalState, setModalState] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState(false)
    const [addresses, setAddresses] = useState()
    const [yesNoId, setYesNoId] = useState()

    const [formData, setFormData] = useState({
        address: '',
        city: '',
        post_code: '',
        country_id: 1,
        api_token: getItem('api_token'),
    })

    const [addressError, setAddressError] = useState()
    const [cityError, setCityError] = useState()
    const [postCodeError, setPostCodeError] = useState()
    const [countryError, setCountryError] = useState()

    const getAddresses = async () => {
        setLoading(true)
        try {
            const addressBooks = await api.get(
                'address_book?api_token=' + getItem('api_token'),
            )
            setAddresses(addressBooks.data)
            setLoading(false)
        } catch (ex) {
            console.log(ex.response)
            const errorResponse = ex.response
            if (errorResponse.status === 401) {
                history.push('/login')
            }
        }
    }

    useEffect(() => {
        getAddresses()
        return () => {
            console.log('clean up')
        }
    }, [])

    const handleFormData = (e) => {
        let tmpData = formData
        tmpData[e.target.name] = e.target.value
        console.log('handleFormData')
        console.log(tmpData)
        setFormData(tmpData)
    }

    const saveAddress = async () => {
        setLoading(true)
        setAddressError('')
        setCityError('')
        setPostCodeError('')
        setCountryError('')
        console.log(formData)
        api.post('address_book', formData)
            .then((response) => {
                setLoading(false)
                const newAddresses = response.data
                console.log('new address')
                console.log(newAddresses)
                console.log('all addresses')
                console.log(addresses)
                //tmpAddresses.push(newAddress)
                setAddresses(newAddresses)
                setSuccess('New address saved')
            })
            .catch((error) => {
                setLoading(false)
                setFormError('')
                const errorResponse = error.response
                console.log(error)
                if (errorResponse.status === 422) {
                    if (errorResponse.data.errors['address']) {
                        setAddressError(
                            errorResponse.data.errors['address'][0],
                        )
                    }
                    if (errorResponse.data.errors['city']) {
                        setCityError(
                            errorResponse.data.errors['city'][0],
                        )
                    }
                    if (errorResponse.data.errors['post_code']) {
                        setPostCodeError(
                            errorResponse.data.errors['post_code'][0],
                        )
                    }
                } else {
                    setFormData(
                        'Internal error occured please contact us',
                    )
                }
                setLoading(false)
            })
    }

    const displayDeleteModal = (id) => {
        setYesNoId(id)
        setModalState(true)
    }

    const deleteAddress = async (id) => {
        setLoading(true)
        console.log('delete address of id:' + id)
        try {
            setLoading(false)
            const response = await api.delete(
                'address_book?id=' +
                    id +
                    '&api_token=' +
                    getItem('api_token'),
            )
            console.log(response.data)
            setAddresses(response.data)
            setSuccess('Address deleted!')
        } catch (ex) {
            console.log(ex)
            setLoading(false)
        }
    }

    const addressElements =
        addresses &&
        addresses.map((address) => {
            return (
                <tr key={address.id}>
                    <td>{address.address}</td>
                    <td>{address.city}</td>
                    <td>{address.post_code}</td>
                    <td>{address.country.name}</td>
                    <td>
                        <button
                            onClick={() => {
                                displayDeleteModal(address.id)
                            }}
                            className="btn btn-danger btn-sm fa fa-trash"
                        ></button>
                    </td>
                </tr>
            )
        })
    return (
        <div>
            {loading && <Loader />}
            {success && <SuccessMessage message={success} />}
            {formError && <ErrorTitle message={formError} />}
            <table className="table">
                <thead>
                    <tr>
                        <th>Address</th>
                        <th>City</th>
                        <th>Post Code</th>
                        <th>Country</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{addressElements}</tbody>
            </table>
            <div className="card">
                <div className="card-header">New Address</div>
                <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                            type="text"
                            name="address"
                            className="form-control"
                            onChange={(e) => {
                                handleFormData(e)
                            }}
                        />
                        {addressError && (
                            <InputError error={addressError} />
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">City</label>
                        <input
                            type="text"
                            name="city"
                            className="form-control"
                            onChange={(e) => {
                                handleFormData(e)
                            }}
                        />
                    </div>
                    {cityError && <InputError error={cityError} />}
                    <div className="form-group">
                        <label htmlFor="address">Post Code</label>
                        <input
                            type="text"
                            className="form-control"
                            name="post_code"
                            onChange={(e) => {
                                handleFormData(e)
                            }}
                        />
                        {postCodeError && (
                            <InputError error={postCodeError} />
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">Country</label>
                        <select className="form-control">
                            <option>Cyprus</option>
                        </select>
                        {countryError && (
                            <InputError error={countryError} />
                        )}
                    </div>
                </div>

                <div className="card-footer">
                    <button
                        onClick={() => {
                            saveAddress()
                        }}
                        className="btn btn-blue  text-right"
                    >
                        Save
                    </button>
                </div>
            </div>

            <YesNoModal
                modalState={modalState}
                name="Address"
                setModalState={setModalState}
                action={deleteAddress}
                id={yesNoId}
            ></YesNoModal>
        </div>
    )
}
