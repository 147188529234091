import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export const Product = (props) => {
    const { t, i18n } = useTranslation()
    const product = props.product
    return product?.name ? (
        <div className={`mt-4 ${props.className}`}>
            <div className={`card`}>
                <div className="card-header ">{product.name}</div>
                <img
                    src={product.images[0]?.image}
                    className="img-fluit"
                />
                <div className="card-body">{product.information}</div>
                <div className="card-footer text-right">
                    <Link
                        to={`/product/${product.id}`}
                        className="btn btn-blue"
                    >
                        {t('buy')}
                    </Link>
                </div>
            </div>
        </div>
    ) : (
        <div></div>
    )
}
