import { getOrderItems } from './LocalStorage'

export const updateOrderItem = (orderItems, sellable) => {
    let updated = false
    orderItems.forEach((item, index) => {
        if (
            item.sellableId === sellable.sellableId &&
            item.sellableType === sellable.sellableType &&
            item.price === sellable.price
        ) {
            updated = true
            orderItems[index] = item
        }
    })
    if (!updated) {
        orderItems.push(sellable)
    }

    return orderItems
}

export const deleteOrderItem = (item) => {
    const items = getOrderItems().filter((orderItem) => {
        if (orderItem.sellableType === item.sellableType) {
            return orderItem.sellableId !== item.sellableId
        }
        return true
    })
    return items
}

export const getOrderTotal = (orderItems = []) => {
    let total = 0
    orderItems.forEach((item) => {
        total += item.quantity * item.price
    })
    return total
}