import React from 'react'
import { Login } from '../Login'
import { Register } from '../Register'

export const LoginRegister = () => {
    return (
        <div id="content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <Register redirect="/checkout" />
                    </div>
                    <div className="col-lg-6">
                        <Login redirect="/checkout" />
                    </div>
                </div>
            </div>
        </div>
    )
}
