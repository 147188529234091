import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import api from './Api/Api'
import {
    getAddressBookId,
    getItem,
    updateShoppingCart,
} from './Helpers/LocalStorage'
import { Loader } from './Loader'

export const AddressBookSelect = () => {
    let history = useHistory()
    const [addresses, setAddresses] = useState()
    const [addressBookId, setAddressBookId] = useState(
        getAddressBookId(),
    )
    const [loading, setLoading] = useState(true)
    const getAddresses = async () => {
        setLoading(true)
        try {
            const response = await api.get(
                'address_book?api_token=' + getItem('api_token'),
            )
            setAddresses(response.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const options =
        addresses &&
        addresses.map((address) => {
            return (
                <option key={address.id} value={address.id}>
                    {address.address}, {address.city},{' '}
                    {address.post_code} {address.country.name}
                </option>
            )
        })

    const addAddress = () => {
        history.push('address-books')
    }

    useEffect(() => {
        getAddresses()
        return () => {}
    }, [])
    return (
        <div className="form-group">
            {loading && <Loader />}
            <label>Please delivery select Address</label>
            {addresses && addresses.length !== 0 && (
                <select
                    value={addressBookId}
                    className="form-control"
                    onChange={(e) => {
                        setAddressBookId(e.target.value)
                        updateShoppingCart(
                            'addressBookId',
                            e.target.value,
                        )
                    }}
                >
                    <option value="">
                        Please select delivery Address
                    </option>
                    {options}
                </select>
            )}
            {addresses && addresses.length === 0 && (
                <button
                    className="btn btn-danger"
                    onClick={addAddress}
                >
                    Add delivery address before checkout
                </button>
            )}
        </div>
    )
}
