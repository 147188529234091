import React from 'react'

export const DonationFooter = () => {
    return (
        <div className="border-top mt-5">
            <div className="h4 text-center mt-1">
                &copy;{new Date().getFullYear()} Cyprus Wildlife
                Research Institute
            </div>
        </div>
    )
}
