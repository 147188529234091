import React, { useEffect } from 'react'
import { DonationInformation } from '../DonationInformation'
import { ProgressBar } from 'react-bootstrap'
import { SocialShare } from '../SocialShare'
import { DonationHeader } from '../DonationHeader'
import { DonationButtons } from '../DonationButtons'
import { GuestCheckout } from '../GuestCheckout'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGet } from '../Hooks/useGet'
import { Donators } from '../Donators'
import { useSelector, useDispatch } from 'react-redux'
import { currenciesSlice, setEuro } from '../../redux/currenciesSlice'
import '../../css/share-button.css'
import { useParams } from 'react-router-dom'
import { DonationFooter } from '../DonationFooter'
import { setLanguage } from '../../redux/languageSlice'
import i18n from '../../i18n'
export const DonationPage = () => {
  const [completePercentage, setCompletePercentage] = useState(
    () => 0,
  )
  const [translationContent, setTranslationContent] = useState()
  const { t } = useTranslation()
  const [amount, setAmount] = useState(0)

  const dispatch = useDispatch()
  const [amountList, setAmountList] = useState(() => [])
  const euro = useSelector((state) => state.currencies.euro)
  const url = useSelector(
    (state) => state.cwri.failedPaymentReturnURL,
  )
  console.log(url)
  const { id } = useParams()
  const [amountChangedCount, setAmountChangedCount] = useState(
    () => 0,
  )
  const [lang, setLang] = useState()
  const { data, error, isLoading } = useGet(`donation/show?id=${id}`)
  const [orderItems, setOrderItems] = useState(() => {
    return {
      sellableType: 'Donation',
      sellableId: id,
      quantity: 1,
      label: 'Donation',
      shippable: 0,
      price: 1,
    }
  })
  //set currencies into redux in order to persist the currency data
  useEffect(() => {
    if (data?.currencies) {
      console.log('data changed locale')
      dispatch(setEuro(data.currencies.euro))
      const sortedList = data.amount_list.sort((a, b) => a - b)
      setAmountList(sortedList)
      console.log(data)
      const max = sortedList[sortedList.length - 1]
      //   setAmount(max > defaultMin ? defaultMin : max)
      setAmount(max)
      updateOrderItems(max)
      const donationTotal = data?.donators?.reduce((pre, current) => {
        if (current.order.status === 'completed') {
          return (pre += current.order.total_amount)
        }
        return 0
      }, 0)
      setCompletePercentage(
        ((donationTotal / data.goal) * 100).toFixed(2),
      )
      updateTranslationContent()
      document.title = data.title
    }

    return () => {}
  }, [data])

  useEffect(() => {}, [amountList.length])

  const updateOrderItems = (amount) => {
    const items = { ...orderItems }
    items.price = amount
    setOrderItems(items)

    setAmountChangedCount((pre) => pre + 1)
  }

  const updateTranslationContent = (lang) => {
    const translationContent = data.translations.find((tr) => {
      return tr.locale === lang
    })
  }

  const changeLanguage = (lang) => {
    window.localStorage.setItem('lang', lang)
    dispatch(setLanguage(lang))
    i18n.changeLanguage(lang)
    // console.log('changing language to ', lang)
    // updateTranslationContent(lang)
  }

  const element = (
    <div className="container">
      <DonationHeader onChangeLanguage={changeLanguage} />
      <div className="row">
        <div className="col-lg-12">
          <h1 className="text-muted mb-2 mt-4">{data?.title}</h1>
        </div>
        <div className="col-lg-7">
          {data?.progress_bar ? (
            <ProgressBar
              now={completePercentage}
              variant="success"
              label={`${completePercentage}% reached`}
              className=" mb-3 "
            />
          ) : (
            ' '
          )}
          <DonationInformation donation={data} />
        </div>
        <div className="col-lg-5 ">
          <DonationButtons
            updateAmount={(a) => {
              setAmount(a)
              updateOrderItems(a)
            }}
            amountList={amountList}
            selected={amount}
            otherAmount={data?.other_amount == 1 ? true : false}
          />

          {amount > 0 && (
            <div className="">
              <div className="mt-5 mb-5 alert alert-info">
                {t('payment_information', {
                  amountTL: amount,
                  amountEuro: euro ? (amount / euro).toFixed(2) : 22,
                })}
              </div>
              <GuestCheckout
                orderItems={{ ...orderItems }}
                showAnonymousCheckbox={true}
              />
              <Donators donators={data ? data.donators : []} />
            </div>
          )}
        </div>
      </div>
      <DonationFooter />
    </div>
  )

  return element
}
