import React, { useState } from 'react'
import { Donation } from './Donation'
import { ErrorTitle } from './ErrorTitle'
import { useGet } from './Hooks/useGet'
import { Loader } from './Loader'
import { Pagination } from './Pagination'
import { useTranslation } from 'react-i18next'
export const Donations = () => {
    const { t } = useTranslation()
    const [url, setUrl] = useState('donations')
    const { data, isLoading, error } = useGet(url)
    const donationElements =
        data?.data.length > 0 ? (
            data.data.map((donation) => {
                return (
                    <Donation
                        className="col-md-6"
                        key={donation.id}
                        donation={donation}
                    />
                )
            })
        ) : (
            <div className="alert alert-info">{t('not_listed')}</div>
        )

    return (
        <div className="container">
            {isLoading && <Loader />}
            {error && <ErrorTitle errors={error} />}
            <div className="row">{donationElements}</div>
            <Pagination data={data} click={setUrl} />
        </div>
    )
}
