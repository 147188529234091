import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { paymentURL } from './Helpers/Config'
import { Loader } from './Loader'
export const ProcessVolunteerPayment = () => {
    const { t } = useTranslation()
    const { orderId } = useParams()
    const failedPaymentReturnURL = useSelector(
        (state) => state.cwri.failedPaymentReturnURL,
    )
    const url = `${paymentURL()}${orderId}&fail_redirect=${encodeURIComponent(
        failedPaymentReturnURL,
    )}`
    return (
        <div className="container">
            <img src="/img/baner.jpg" className="img-fluid" />
            <div className="card">
                <div className="card-header">Processing Payment</div>
                <div className="alert alert-warning">
                    Please follow the instuctions below if screen is
                    empty please wait until you get response from your
                    bank
                </div>
                <iframe
                    src={url}
                    id="iframe"
                    style={{
                        marginTop: '20px',
                        border: 'none',
                        width: '100%',
                        height: '500px',
                        position: 'relative',
                    }}
                ></iframe>
                <div className="card-footer"></div>
            </div>
        </div>
    )
}
