import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from '../../i18n'
import { setLanguage } from '../../redux/languageSlice'

export const useLanguage = () => {
  const dispatch = useDispatch()
  const presetlanguage = window.localStorage.getItem('lang')
  useEffect(() => {
    if (presetlanguage) {
      dispatch(setLanguage(presetlanguage))
      i18n.changeLanguage(presetlanguage)
    } else {
      let lang = 'en'
      if (navigator.language.includes('tr')) {
        lang = 'tr'
      }
      window.localStorage.setItem('lang', lang)
      dispatch(setLanguage(lang))
      i18n.changeLanguage(lang)
    }
  }, [])
  return null
}
