import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuidv4'
export const CustomFields = ({
  customFields,
  updateCustomFieldValues,
  errors,
}) => {
  const { t } = useTranslation()
  const [values, setValues] = useState({})
  const updateValues = (field, e) => {
    let value = null
    if (field.type == 'text') {
      value = e.target.value
    }
    if (field.type == 'file') {
      value = e.target.files[0]
    }
    setValues({ ...values, [field.id]: value })
  }

  useEffect(() => {
    if (Object.keys(values).length > 0) {
      updateCustomFieldValues(values)
    }
  }, [values])

  useEffect(() => {
    customFields?.forEach((field) => {
      setValues({ ...values, [field.id]: '' })
    })
  }, [])

  return (
    <div className="card">
      <div className="card-header">{t('customProductFields')}</div>
      <div className="card-body">
        {customFields?.map((field) => (
          <div className="form-group" key={field.id}>
            <label>
              {field.label}
              {field.required == 1 && (
                <span className="fw-bolder" title="required">
                  (*)
                </span>
              )}
            </label>
            {field.type === 'text' && (
              <input
                required={field.required == 1}
                type={`${field.type}`}
                value={values[field.id]}
                className="form-control"
                onChange={(e) => updateValues(field, e)}
              />
            )}
            {field.type === 'file' && (
              <input
                required={field.required == 1}
                type={`${field.type}`}
                className="form-control"
                onChange={(e) => updateValues(field, e)}
              />
            )}
          </div>
        ))}
        {errors && (
          <div className="alert alert-danger mt-3">{errors}</div>
        )}
      </div>
    </div>
  )
}
