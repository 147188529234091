import { configureStore } from '@reduxjs/toolkit'
import languageReducer from './languageSlice'
import loggedInReducer from './loggedInSlice'
import currenciesSlice from './currenciesSlice'
import cwriReducer from './cwriSlice'
export default configureStore({
    reducer: {
        language: languageReducer,
        loggedIn: loggedInReducer,
        currencies: currenciesSlice,
        cwri: cwriReducer,
    },
})
