import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const GuestAddressBook = ({ handleFormData, errors }) => {
  const { t } = useTranslation()
  const [pickup, setPickup] = useState(0)

  return (
    <div className="card">
      <div className="card-header">{t('postage_address')}</div>
      <div className="card-body">
        <div className="form-switch form-check">
          <label htmlFor="pickup" className="form-check-label">
            {t('pickup_from_taskent')}
          </label>
          <input
            type="checkbox"
            id="pickup"
            checked={pickup}
            onChange={() => {
              const value = pickup == 0 ? 1 : 0
              handleFormData({
                target: {
                  name: 'pickup',
                  value: value,
                },
              })
              setPickup(value)
            }}
            className="form-check-input"
          />
        </div>
        {!pickup && (
          <>
            <div className="form-group">
              <label>{t('address')}</label>
              <input
                autoComplete="new-address"
                type="text"
                className="form-control"
                name="address"
                onChange={handleFormData}
              />
              {errors?.address && (
                <div className="alert alert-danger">
                  {errors.address}
                </div>
              )}
            </div>
            <div className="form-group">
              <label>{t('post_code')}</label>
              <input
                autoComplete="new-address"
                type="text"
                className="form-control"
                name="post_code"
                onChange={handleFormData}
              />
            </div>
            {errors?.post_code && (
              <div className="alert alert-danger">
                {errors.post_code}
              </div>
            )}
            <div className="form-group">
              <label>{t('city')}</label>
              <input
                name="city"
                onChange={handleFormData}
                className="form-control"
              />
              {errors?.city && (
                <div className="alert alert-danger">
                  {errors.city}
                </div>
              )}
            </div>

            <div className="form-group">
              <label>{t('country')}</label>
              <input
                name="country"
                onChange={handleFormData}
                className="form-control"
              />
              {errors?.country && (
                <div className="alert alert-danger">
                  {errors?.country}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
