import React, { useEffect, useState } from 'react'
import { useGet } from './Hooks/useGet'
import configData from '../config/default.json'
import { Pagination } from './Pagination'
import { Product } from './Product'
import { ProductFilter } from './ProductFilter'
import { Loader } from './Loader'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { v4 } from 'uuid'
import { useSelector } from 'react-redux'
import api from './Api/Api'
export const Products = () => {
    let { categoryId } = useParams()

    let productsEndpoint = categoryId
        ? 'products?category_id=' + categoryId
        : 'products'
    const [url, setUrl] = useState(productsEndpoint)
    const [isLoading, setIsLoading] = useState(true)
    const language = useSelector((state) => state.language)
    const [products, setProducts] = useState()

    const getProducts = async (url) => {
        console.log('url chaged', url)
        setIsLoading(true)
        try {
            const response = await api.get(url, {
                headers: {
                    Language: language.lang,
                },
            })
            console.log(response.data)
            const mainProduct = response?.data.data.find((p) => {
                return p.id === 30
            })
            response?.data.data.unshift(mainProduct)
            setProducts(response.data)
            setIsLoading(false)
        } catch (err) {
            console.log(err)
            if (axios.isCancel(err)) {
                console.log('aborting request')
            } else {
                console.error(err)
                setIsLoading(false)
            }
        }
    }
    useEffect(() => {
        productsEndpoint = categoryId
            ? 'products?category_id=' + categoryId
            : 'products'
        const tmpUrl = productsEndpoint
        console.log(productsEndpoint)
        // categoryId
        setUrl(tmpUrl)
        const result = getProducts(tmpUrl)
        return () => {
            if (!result) {
                return '<div></div>'
            }
        }
    }, [categoryId])

    return (
        <div className="container">
            <ProductFilter />
            {isLoading && <Loader />}
            <div className="row">
                {products &&
                    products.data.map((product) => {
                        if (product?.skus?.length > 0)
                            return (
                                <Product
                                    key={v4()}
                                    className="col-md-4"
                                    product={product}
                                ></Product>
                            )
                    })}
            </div>
            <Pagination data={products} click={setUrl} />
        </div>
    )
}
