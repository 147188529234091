import React, { useState } from 'react'

export const ShoppingCartItem = (props) => {
  const item = props.item
  const [quantity, setQuantity] = useState(item.quantity)

  const updateQuantity = (quantity) => {
    setQuantity(quantity)
    item.quantity = quantity
    props.update(item)
  }
  const deleteItem = (it) => {
    props.delete(it)
  }
  return (
    <tr>
      <td>
        <a href="#">{item.label}</a>
      </td>
      <td>
        <input
          type="number"
          value={quantity}
          min={1}
          max={9}
          onChange={(e) => {
            updateQuantity(e.target.value)
          }}
          className="form-control w-100"
        />
      </td>
      <td>{item.price}</td>
      <td>{item.price * item.quantity}</td>
      <td>
        <a
          href="#"
          className="text-danger"
          onClick={() => deleteItem(item)}
        >
          <i className="fa fa-trash"></i>
        </a>
      </td>
    </tr>
  )
}
