import React, { useState } from 'react'
import jQuery from 'jquery'
import { useHistory } from 'react-router-dom'
import { updateOrderItem } from './Helpers/ManageCart'
import {
    getOrderItems,
    updateShoppingCart,
} from './Helpers/LocalStorage'
import { useTranslation } from 'react-i18next'
window.jQuery = jQuery
export const Adopt = (props) => {
    const animal = props.animal

    let history = useHistory()
    const { t } = useTranslation()

    const [other, setOther] = useState(false)
    const [priceError, setPriceError] = useState()
    const [donationText, setDonationText] = useState()
    const [sellable, setSellable] = useState({
        sellableType: 'Animal',
        sellableId: animal.id,
        quantity: 1,
        price: 0,
        label: `Donation for ${animal.name}`,
        shippable: 0,
    })

    const addToCart = () => {
        if (sellable.price === 0) {
            setPriceError(t('donationAmountError'))
            return
        }
        const previousOrderItems = getOrderItems()
        const orderItems = updateOrderItem(
            previousOrderItems,
            sellable,
        )
        updateShoppingCart('orderItems', orderItems)
        history.push('/cart')
    }

    const [donationAmount, setDonationAmount] = useState(100)

    const updateSellableObject = (amount) => {
        const tmpSellable = sellable
        tmpSellable.price = parseInt(amount, 10)
        setSellable(tmpSellable)
    }

    const displayOther = (display) => {
        setOther(display)
    }

    const displayDonationText = (amount) => {
        setDonationAmount(amount)
        updateSellableObject(amount)
        setPriceError('')
        setDonationText(t('thanksDonation', { amount: amount }))
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    {t('donationFor', { donation: animal.name })}
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <button
                                className="btn btn-default w-100"
                                onClick={() => {
                                    displayOther(false)
                                    displayDonationText(10)
                                }}
                            >
                                10
                            </button>
                        </div>
                        <div className="col-md-3">
                            <button
                                className="btn btn-primary w-100"
                                onClick={() => {
                                    displayOther(false)
                                    displayDonationText(50)
                                }}
                            >
                                50
                            </button>
                        </div>
                        <div className="col-md-3">
                            <button
                                className="btn btn-success w-100"
                                onClick={() => {
                                    displayOther(false)
                                    displayDonationText(100)
                                }}
                            >
                                100
                            </button>
                        </div>
                        <div className="col-md-3">
                            <button
                                className="btn btn-secondary w-100"
                                onClick={() => {
                                    displayOther(true)
                                    displayDonationText(100)
                                }}
                            >
                                Other
                            </button>
                        </div>
                    </div>
                    <div
                        className="row mt-4"
                        style={
                            other
                                ? { display: 'block' }
                                : { display: 'none' }
                        }
                    >
                        <div className="col-md-12">
                            <input
                                type="number"
                                value={donationAmount}
                                onChange={(e) => {
                                    updateSellableObject(
                                        e.target.value,
                                    )
                                    displayDonationText(
                                        e.target.value,
                                    )
                                }}
                                className="form-control w-100"
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            {donationText}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button
                                onClick={() => {
                                    addToCart()
                                }}
                                className="btn btn-blue w-100 mt-4"
                            >
                                {t('donate')}
                            </button>
                            {priceError && (
                                <div className="alert alert-danger">
                                    {priceError}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row"></div>
        </div>
    )
}
