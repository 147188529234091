import React from 'react'
import { AccountNavigation } from '../AccountNavigation'
import { AddressBook } from '../AddressBook'

export const AddressBookPage = () => {
    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-8">
                    <AddressBook />
                </div>
                <div className="col-lg-4">
                    <AccountNavigation />
                </div>
            </div>
        </div>
    )
}
