import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import { About } from './About'
import { AnimalDetails } from './AnimalDetails'
import { Nav } from './Nav'
import { NewsList } from './NewsList'
import { ShoppingCart } from './ShoppingCart'
import { useCookies } from 'react-cookie'
import { clientID } from './Helpers/ClientId'
import { Products } from './Products'
import { ProductDetails } from './ProductDetails'
import { Checkout } from './Pages/Checkout'
import { Register } from './Register'
import { Dashboard } from './Pages/Dashboard'
import { MyOrders } from './Pages/MyOrders'
import { Password } from './Pages/Password'
import { CustomerDetails } from './Pages/CustomerDetails'
import { ProcessPayment } from './ProcessPayment'
import { OrderDetails } from './OrderDetails'
import { Events } from './Events'
import { EventDetails } from './EventDetails'
import { LoginPage } from './Pages/LoginPage'
import { AddressBookPage } from './Pages/AddressBookPage'
import { Donations } from './Donations'

import { useDispatch } from 'react-redux'
import i18n from '../i18n'
import { setLanguage } from '../redux/languageSlice'
import { ResearchPayment } from './ResearchPayment'
import { ProcessVolunteerPayment } from './ProcessVolunteerPayment'
import { CwriPayment } from './CwriPayment'
import { LinkGenerator } from './Pages/LinkGenerator'
import { DonationPage } from './Pages/DonationPage'
import { EventPage } from './Pages/EventPage'
import { useLanguage } from './Hooks/useLanguage'
export const App = () => {
  let dispatch = useDispatch()
  useLanguage()

  const changeLanguage = (lang) => {
    window.localStorage.setItem('lang', lang)
    i18n.changeLanguage(lang)
    dispatch(setLanguage(lang))
  }

  const [cookies, setCookie] = useCookies(['clientId'])
  if (!cookies || !cookies.clientId) {
    setCookie('clientId', clientID(), { path: '/' })
  }

  return (
    <div>
      <Router>
        <div className="content">
          <Switch>
            <Route
              exact
              path="/research-project-volunteering/:name/:amount"
            >
              <ResearchPayment />
            </Route>
            <Route exact path="/donate/:id">
              <DonationPage />
            </Route>
            <Route exact path="/cwri-volunteering/:name/:amount">
              <CwriPayment />
            </Route>
            <Route exact path="/link-generator">
              <LinkGenerator />
            </Route>
            <Route exact path="/process-payment-volunteer/:orderId">
              <ProcessVolunteerPayment />
            </Route>
            <Route exact path="/evnt/:id">
              <EventPage />
            </Route>
            <Route exact path="/">
              <EventPage id="37" />
            </Route>
            <Route exact path="/about">
              <Nav changeLanguage={changeLanguage} />
              <About />
            </Route>
            <Route exact path="/news">
              <Nav changeLanguage={changeLanguage} />
              <NewsList />
            </Route>
            <Route exact path="/animal/:id">
              <Nav changeLanguage={changeLanguage} />
              <AnimalDetails />
            </Route>
            <Route exact path="/cart">
              <Nav changeLanguage={changeLanguage} />
              <ShoppingCart />
            </Route>
            <Route exact path="/products">
              <Nav changeLanguage={changeLanguage} />
              <Products />
            </Route>
            <Route exact path="/product/category/:categoryId">
              <Nav changeLanguage={changeLanguage} />
              <Products />
            </Route>
            <Route exact path="/events-list">
              <Nav changeLanguage={changeLanguage} />
              <Events />
            </Route>
            <Route exact path="/events/:id">
              <Nav changeLanguage={changeLanguage} />
              <EventDetails />
            </Route>
            <Route exact path="/product/:id">
              <ProductDetails />
            </Route>

            <Route exact path="/checkout">
              <Nav changeLanguage={changeLanguage} />
              <Checkout />
            </Route>
            <Route exact path="/login">
              <Nav changeLanguage={changeLanguage} />
              <LoginPage />
            </Route>
            <Route exact path="/register">
              <Nav changeLanguage={changeLanguage} />
              <Register />
            </Route>
            <Route exact path="/dashboard">
              <Nav changeLanguage={changeLanguage} />
              <Dashboard />
            </Route>
            <Route exact path="/my-orders">
              <Nav changeLanguage={changeLanguage} />
              <MyOrders />
            </Route>
            <Route exact path="/change-password">
              <Nav changeLanguage={changeLanguage} />
              <Password />
            </Route>
            <Route exact path="/personal-details">
              <Nav changeLanguage={changeLanguage} />
              <CustomerDetails />
            </Route>
            <Route exact path="/process-payment/:orderId">
              <Nav changeLanguage={changeLanguage} />
              <ProcessPayment />
            </Route>

            <Route exact path="/orders/:id">
              <Nav changeLanguage={changeLanguage} />
              <OrderDetails />
            </Route>
            <Route exact path="/order/:id/completed">
              <Nav changeLanguage={changeLanguage} />
              <OrderDetails deleteShoppingCart={true} />
            </Route>
            <Route exact path="/address-books">
              <Nav changeLanguage={changeLanguage} />
              <AddressBookPage />
            </Route>
            <Route exact path="/donations">
              <Nav changeLanguage={changeLanguage} />
              <Donations />
            </Route>

            <Route exact path="/donation/:id">
              <Nav changeLanguage={changeLanguage} />
              <DonationPage />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  )
}
