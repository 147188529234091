import React, { useState } from 'react'
import { ShoppingCartItem } from './ShoppingCartItem'
import {
    updateOrderItem,
    deleteOrderItem,
    getOrderTotal,
} from './Helpers/ManageCart'
import {
    getOrderItems,
    updateShoppingCart,
} from './Helpers/LocalStorage'
import { useHistory } from 'react-router-dom'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const ShoppingCart = () => {
    let history = useHistory()

    const { t } = useTranslation()
    const [orderItems, setOrderItems] = useState(() =>
        getOrderItems(),
    )

    const [orderTotal, setOrderTotal] = useState(
        getOrderTotal(orderItems),
    )

    const _setOrderItems = (orderItems) => {
        updateShoppingCart('orderItems', orderItems)
        setOrderItems(orderItems)
        const total = getOrderTotal(getOrderItems())
        setOrderTotal(total)
    }

    const update = (item) => {
        _setOrderItems(updateOrderItem(orderItems, item))
        setOrderTotal(getOrderTotal(orderItems))
    }

    const deleteItem = (item) => {
        const remainItems = deleteOrderItem(item)
        _setOrderItems(remainItems)
        setOrderTotal(getOrderTotal(remainItems))
    }

    const checkout = () => {
        history.push('/checkout')
    }

    const cartItems = orderItems.map((item) => {
        return (
            <ShoppingCartItem
                key={item.id}
                item={item}
                update={update}
                delete={deleteItem}
            />
        )
    })

    return (
        <div className="container">
            <div className="row mt-4">
                <div className="col-lg-12">
                    <form>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{t('quantity')}</th>
                                        <th>{t('unitPrice')}</th>
                                        <th>{t('total')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartItems.length === 0 ? (
                                        <tr>
                                            <td>
                                                {t(
                                                    'noItemShoppingCard',
                                                )}
                                            </td>
                                        </tr>
                                    ) : (
                                        cartItems
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <th>{t('total')}</th>
                                        <th>{orderTotal}</th>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="box-footer d-flex justify-content-between align-items-center">
                            <div className="left-col"></div>
                            <div className="right-col">
                                {orderTotal !== 0 && (
                                    <div>
                                        <br />
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                checkout()
                                            }}
                                            className="btn btn-template-outlined mt-3"
                                        >
                                            {t('proceedToCheckout')}
                                            <i className="fa fa-chevron-right"></i>
                                        </button>
                                    </div>
                                )}
                                {orderTotal === 0 && (
                                    <Link
                                        to="/products"
                                        className="btn btn-primary"
                                    >
                                        {t('continueShopping')}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
