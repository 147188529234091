import React from 'react'
import { Login } from '../Login'

export const LoginPage = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <Login />
                </div>
            </div>
        </div>
    )
}
