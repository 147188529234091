import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import api from './Api/Api'
import {
  getOrderItems,
  updateShoppingCart,
} from './Helpers/LocalStorage'
import { updateOrderItem } from './Helpers/ManageCart'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useGet } from './Hooks/useGet'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useSelector } from 'react-redux'

export const EventDetails = () => {
  const { t } = useTranslation()
  let history = useHistory()
  let { id } = useParams()

  const [eventDetails, setEventDetails] = useState([])
  const language = useSelector((state) => state.language)
  // const { data, isLoading, error } = useGet(url)
  let data = null
  const [details, setDetails] = useState()

  const getEvent = async () => {
    const url = 'events/show?id=' + id
    try {
      const response = await api.get(url, {
        headers: {
          Language: language.lang,
        },
      })
      console.log(response)
      setEventDetails(response.data)
      data = response.data
      const tmpDetails =
        data &&
        data.future_details.map((detail) => {
          console.log(detail)
          return (
            <tr key={detail.id}>
              <td>
                {moment(detail.event_date).format('DD-MM-YYYY HH:mm')}
              </td>
              <td>
                {detail.availablity}/{detail.capacity}
              </td>
              <td>
                <input
                  type="number"
                  id={`quantity${detail.id}`}
                  className="form-control"
                  disabled={detail.availablity === 0}
                  max={detail.availablity}
                  min={1}
                />
              </td>
              <td>{data.price}</td>
              <td>
                <button
                  disabled={detail.availablity === 0}
                  onClick={() => {
                    addToCart(detail)
                  }}
                  className={`btn btn-sm ${
                    detail.availablity === 0
                      ? 'btn-danger'
                      : 'btn-blue'
                  }`}
                >
                  {detail.availablity === 0 ? t('soldout') : t('buy')}
                </button>
              </td>
            </tr>
          )
        })
      setDetails(tmpDetails)
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    getEvent()
  }, [])

  const addToCart = (detail) => {
    const orderItems = getOrderItems()
    const q = document.getElementById(`quantity${detail.id}`).value
    const quantity = q ? q : 1
    console.log('%c event details ', 'color:#000;font-size:200%')
    console.log(detail)

    const sellable = {
      sellableType: 'EventDetail',
      sellableId: detail.id,
      quantity: quantity,
      price: quantity * data.price,
      label: `Event ${detail.event.title}`,
      shippable: 0,
    }
    console.log(
      '%c add to card',
      'color:#ff0000;font-size:130%;font-weight:bold',
    )
    console.log(sellable)
    const updatedOrderItems = updateOrderItem(orderItems, sellable)
    updateShoppingCart('orderItems', updatedOrderItems)

    history.push('/cart')
  }

  const image =
    eventDetails && eventDetails.images
      ? eventDetails.images[0].image
      : 'https://via.placeholder.com/1200x500'

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-7">
          <div className="card">
            <div className="card-header">
              {eventDetails && eventDetails.title}
            </div>
            <div className="image">
              <img
                src={image}
                className="img-fluid"
                alt="eventDetails image"
              />
            </div>
            <div className="card-body">
              <p>{eventDetails && eventDetails.information}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="card">
            <div className="card-header">
              {t('eventAvailability')}
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>{t('date')}</th>
                  <th>{t('availableCapacity')}</th>
                  <th>{t('quantity')}</th>
                  <th>{t('price')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{details}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
