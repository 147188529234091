import React from 'react'
import { PaginationItem } from './PaginationItem'

export const Pagination = (props) => {
    const data = props.data
    let links = null

    if (data) {
        links = data.links
        let index = 0
        const paginationItems = links.map((link) => {
            index += 1
            return (
                <PaginationItem
                    key={index}
                    link={link}
                    setLink={() => {
                        if (link.url) {
                            props.click(link.url)
                        }
                    }}
                />
            )
        })
        return (
            <nav>
                <ul className="pagination">{paginationItems}</ul>
            </nav>
        )
    } else {
        return <div></div>
    }
}
