import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'

export const LinkGenerator = () => {
  const refLink = useRef()
  const type = {
    529: 'cwri-volunteering',
    528: 'research-project-volunteering',
    527: 'research-project-volunteering',
  }
  const [linkGenerated, setLinkGenerated] = useState(() => '')
  const [link, setLink] = useState(() => {
    return {
      name: '',
      amount: '',
      type: 529,
    }
  })
  const [error, setError] = useState(() => false)
  const setData = (e) => {
    const tmp = { ...link }
    tmp[e.target.name] = e.target.value
    setLink(tmp)
  }

  const generateLink = () => {
    const url = process.env.REACT_APP_FRONTEND_URL
    let error = false
    Object.keys(link).forEach((key) => {
      if (link[key] === '') {
        error = 'All fields are required'
      }
    })
    if (isNaN(link.amount)) {
      error = 'Amount must be number'
    }
    setError(error)
    if (error) {
      return
    }
    setLinkGenerated(
      `${url}${type[link.type]}/${link.name.replace(' ', '%20')}/${
        link.amount
      }`,
    )
    refLink.current?.focus()
    refLink.current?.select()
  }

  useEffect(() => {
    if (!error) {
      navigator.clipboard.writeText(linkGenerated)
    }
  }, [linkGenerated])
  return (
    <div className="container">
      <div className="card">
        <div className="card-header">Link Generator</div>
        <div className="card-body">
          <div className="form-group">
            <label>Link For</label>
            <select
              className="form-control"
              onChange={(e) => setData(e)}
              name="type"
            >
              <option value="529">CWRI Link</option>
              <option value="528">CMC Link</option>
            </select>
          </div>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              onChange={(e) => setData(e)}
            />
          </div>
          <div className="form-group">
            <label>Amount</label>
            <input
              type="text"
              className="form-control"
              name="amount"
              onChange={(e) => setData(e)}
            />
          </div>
          {linkGenerated && (
            <input
              ref={refLink}
              type="text"
              value={linkGenerated}
              className="form-control"
            />
          )}
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="card-footer text-right">
          <div
            className="btn btn-sm btn-primary"
            onClick={generateLink}
          >
            Generate
          </div>
        </div>
      </div>
    </div>
  )
}
