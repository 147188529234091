import React from 'react'
import { getOrderItems } from './Helpers/LocalStorage'

export const CheckoutSummary = () => {
    const orderItems = getOrderItems()
    let total = 0
    const summary = orderItems.map((item) => {
        total += item.price * item.quantity
        return (
            <tr key={item.sellableId}>
                <td>{item.label}</td>
                <td>{item.quantity}</td>
                <td>{item.price * item.quantity}</td>
            </tr>
        )
    })
    return (
        <div className="card">
            <div className="card-header">Order Summary</div>
            <table className="table">
                {summary}
                <tr>
                    <td></td>
                    <th>Total</th>
                    <td>{total}</td>
                </tr>
            </table>
        </div>
    )
}
