import React from 'react'
import { useHistory } from 'react-router'
import { getItem } from './Helpers/LocalStorage'
import { API_TOKEN } from '../config/default.json'
export const Auth = () => {
    let history = useHistory()
    if (getItem(API_TOKEN) === null) {
        history.push('/login')
    }
    return <div></div>
}
