import { createSlice } from '@reduxjs/toolkit'

export const currenciesSlice = createSlice({
    name: 'currencies',
    initialState: {
        euro: 0,
        gbp: 0,
        usd: 0,
    },
    reducers: {
        setEuro: (state, action) => {
            state.euro = action.payload
        },
        setGbp: (state, action) => {
            state.gbp = action.payload
        },
        setUsd: (state, action) => {
            state.usd = action.payload
        },
    },
})

export const { setEuro, setGbp, setUsd } = currenciesSlice.actions
export default currenciesSlice.reducer
