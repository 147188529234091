import { createSlice } from '@reduxjs/toolkit'

export const cwriSlice = createSlice({
    name: 'cwri',
    initialState: {
        failedPaymentReturnURL: '',
    },
    reducers: {
        setFailedPaymentURL: (state, action) => {
            state.failedPaymentReturnURL = action.payload
        },
    },
})

export const { setFailedPaymentURL } = cwriSlice.actions
export default cwriSlice.reducer
