import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import { API_TOKEN } from '../config/default.json'
export const Nav = (props) => {
  const loggedIn = window.localStorage.getItem(API_TOKEN)

  const { t, i18n } = useTranslation()

  return (
    <div>
      <header className="nav-holder make-sticky">
        <div className="top-bar">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-6 d-md-block d-none">
                <p>
                  <button onClick={() => props.changeLanguage('tr')}>
                    TR
                  </button>
                  <button onClick={() => props.changeLanguage('en')}>
                    EN
                  </button>
                  {/* {t('contactUsText')} */}
                </p>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-md-end justify-content-between">
                  <ul className="list-inline contact-info d-block d-md-none">
                    <li className="list-inline-item">
                      <NavLink to="/" activeClassName="active">
                        <i className="fa fa-phone"></i>
                      </NavLink>
                    </li>
                    <li className="list-inline-item">
                      <NavLink to="/" activeClassName="active">
                        <i className="fa fa-envelope"></i>
                      </NavLink>
                    </li>
                  </ul>
                  <div className="login">
                    <NavLink
                      to="/cart"
                      data-toggle="modal"
                      data-target="#login-modal"
                      className="login-btn"
                    >
                      <i className="fa fa-shopping-basket"></i>
                      <span className="d-none d-md-inline-block">
                        {t('shoppingBasket')}
                      </span>
                    </NavLink>
                  </div>

                  {!loggedIn && (
                    <div className="login">
                      <NavLink
                        to="/login"
                        data-toggle="modal"
                        data-target="#login-modal"
                        className="login-btn"
                      >
                        <i className="fa fa-sign-in"></i>
                        <span className="d-none d-md-inline-block">
                          {t('signIn')}
                        </span>
                      </NavLink>
                      <NavLink
                        to="/register"
                        href="customer-register.html"
                        className="signup-btn"
                      >
                        <i className="fa fa-user"></i>
                        <span className="d-none d-md-inline-block">
                          {t('signUp')}
                        </span>
                      </NavLink>
                    </div>
                  )}
                  {loggedIn && (
                    <div className="login">
                      <NavLink
                        to="/dashboard"
                        data-toggle="modal"
                        data-target="#login-modal"
                        className="login-btn"
                      >
                        <i className="fa fa-dashboard"></i>
                        <span className="d-none d-md-inline-block">
                          {t('profile')}
                        </span>
                      </NavLink>
                    </div>
                  )}
                  <ul className="social-custom list-inline">
                    <li className="list-inline-item">
                      <NavLink to="/" activeClassName="active">
                        <i className="fa fa-facebook"></i>
                      </NavLink>
                    </li>
                    <li className="list-inline-item">
                      <NavLink to="/" activeClassName="active">
                        <i className="fa fa-google-plus"></i>
                      </NavLink>
                    </li>
                    <li className="list-inline-item">
                      <NavLink to="/" activeClassName="active">
                        <i className="fa fa-twitter"></i>
                      </NavLink>
                    </li>
                    <li className="list-inline-item">
                      <NavLink to="/" activeClassName="active">
                        <i className="fa fa-envelope"></i>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
                    id="navbar"
                    role="navigation"
                    className="navbar navbar-expand-lg"
                >
                    <div className="container">
                        <NavLink to="/" className="navbar-brand home">
                            <img
                                src="/img/logo.png"
                                alt="koru logo"
                                width="187"
                                className="d-none d-md-inline-block"
                            />
                            <img
                                src="img/logo-small.png"
                                alt="koru logo"
                                className="d-inline-block d-md-none"
                            />
                            <span className="sr-only">KORU</span>
                        </NavLink>
                        <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#navigation"
                            className="navbar-toggler btn-template-outlined"
                        >
                            <span className="sr-only">
                                Toggle navigation
                            </span>
                            <i className="fa fa-align-justify"></i>
                        </button>
                        <div
                            id="navigation"
                            className="navbar-collapse collapse"
                        >
                            <ul className="nav navbar-nav ml-auto">
                                <li className="nav-item dropdown">
                                    <NavLink
                                        to="/"
                                        activeClassName="active"
                                    >
                                        {t('home')}
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink
                                        to="/donations"
                                        activeClassName="active"
                                    >
                                        {t('donations')}
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink
                                        to="/products"
                                        activeClassName="active"
                                    >
                                        {t('shop')}
                                    </NavLink>
                                </li>
                                <li className="nav-item ">
                                    <NavLink
                                        to="/events-list"
                                        activeClassName="active"
                                    >
                                        {t('events')}
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink
                                        to="/news"
                                        activeClassName="active"
                                    >
                                        {t('news')}
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        href="#"
                                        data-toggle="dropdown"
                                        data-hover="dropdown"
                                        data-delay="200"
                                        className="dropdown-toggle"
                                    >
                                        {t('getInvolve')}
                                        <b className="caret"></b>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-item">
                                            <Link
                                                to="/"
                                                className="nav-link"
                                            >
                                                {t('fundraise')}
                                            </Link>
                                        </li>
                                        <li className="dropdown-item">
                                            <Link
                                                to="/"
                                                className="nav-link"
                                            >
                                                {t(
                                                    'corporatePartners',
                                                )}
                                            </Link>
                                        </li>
                                        <li className="dropdown-item">
                                            <Link
                                                to="/"
                                                className="nav-link"
                                            >
                                                {t('workWithUs')}
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        href="#"
                                        data-toggle="dropdown"
                                        className="dropdown-toggle"
                                    >
                                        {t('contact')}
                                        <b className="caret"></b>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-item">
                                            <Link
                                                to="/"
                                                className="nav-link"
                                            >
                                                Contact option 1
                                            </Link>
                                        </li>
                                        <li className="dropdown-item">
                                            <Link
                                                to="/"
                                                className="nav-link"
                                            >
                                                Contact option 2
                                            </Link>
                                        </li>
                                        <li className="dropdown-item">
                                            <Link
                                                to="/"
                                                className="nav-link"
                                            >
                                                Contact option 3
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
      </header>
    </div>
  )
}
