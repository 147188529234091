import React, { useState } from 'react'
import { DonationHeader } from '../DonationHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import i18n from '../../i18n'
import { setLanguage } from '../../redux/languageSlice'
import { useGet } from '../Hooks/useGet'
import { useEffect } from 'react'
import { GuestCheckout } from '../GuestCheckout'
import { SingleEvent } from '../SingleEvent'
import moment from 'moment'
import { InputError } from '../InputError'
import { Footer } from '../Footer'
import { DonationFooter } from '../DonationFooter'

export const EventPage = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const params = useParams()
  const [displayCheckout, setDisplayCheckout] = useState(false)
  const [quantityError, setQuantityError] = useState(() => {})
  const { id } = useParams()
  const [testId, setTestId] = useState(37)
  const [eventDates, setEventDates] = useState()
  const { data, error, isLoading } = useGet(
    'events/show?id=' + params.id,
  )

  const [orderItems, setOrderItems] = useState(() => {
    return []
  })
  const changeLanguage = (lang) => {
    window.localStorage.setItem('lang', lang)
    dispatch(setLanguage(lang))
    i18n.changeLanguage(lang)
  }

  const addToCart = (detail) => {
    console.log('%c event details ', 'color:#000;font-size:200%')
    console.log(detail)

    if (parseInt(detail.quantity) > detail.availablity) {
      const error = {}
      error[detail.id] = t('availablity_error', {
        max: detail.availablity,
      })
      console.log(error)
      console.error('setting error')
      setQuantityError(error)
      return
    } else {
      const error = {}
      error[detail.id] = ''
      console.log(error)
      console.error('setting error')
      setQuantityError(error)
    }
    const sellable = {
      sellableType: 'EventDetail',
      sellableId: detail.id,
      quantity: detail.quantity,
      price: data.price,
      label: `Event ${detail.event.title}`,
      shippable: 0,
      total: data.price * detail.quantity,
    }
    let updated = false
    const tmp = [...orderItems]
    for (let i = 0; i < tmp.length; ++i) {
      if (tmp[i].sellableId === detail.id) {
        tmp[i].quantity = detail.quantity
        tmp[i].price = data.price
        tmp[i].total = data.price * detail.quantity
        updated = true
        break
      }
    }
    if (!updated) {
      tmp.push(sellable)
    }
    setDisplayCheckout(true)
    setOrderItems(tmp)
  }
  const getTotal = (prop) => {
    let total = 0

    orderItems.forEach((item) => {
      return (total += parseFloat(item[prop]))
    })
    console.log('get ', prop, total)
    return total
  }

  useEffect(() => {
    if (id) setTestId(id)
  }, [id])

  useEffect(() => {
    if (!data) return
    const tmpEventDates = data?.future_details.map(
      (detail, index) => {
        detail.quantity = 0
        if (index === 0) {
          detail.quantity = 2
        }
        return detail
      },
    )
    setEventDates(tmpEventDates)
    document.title = data.title
  }, [data])

  useEffect(() => {
    if (eventDates) {
      eventDates?.forEach((event) => {
        if (event?.quantity) addToCart(event)
      })
    }
  }, [eventDates])

  return (
    <div className="container">
      <DonationHeader onChangeLanguage={changeLanguage} />
      {data?.online == 1 && (
        <div className="row">
          <div className="col-lg-12 mt-4">
            <h2 className="text-muted">{data?.title}</h2>
          </div>
          <div className="col-lg-7">
            <SingleEvent event={data} />
          </div>
          <div className="col-lg-5">
            <div className="card mt-4">
              <div className="card-header">
                {t('available_dates')}
              </div>
              {eventDates?.map((detail) => {
                return (
                  <div key={detail.id}>
                    <div className="d-flex align-items-center justify-content-between p-3">
                      <div className="flex-fill">
                        {moment(detail.event_date).format(
                          'DD-MM-YYYY HH:mm',
                        )}
                      </div>
                      <div className=" flex-fill ml-2">
                        {detail.availablity > 0 ? (
                          <input
                            type="number"
                            max={detail.availablity}
                            min={0}
                            placeholder={t('quantity')}
                            id={`quantity${detail.id}`}
                            value={detail.quantity}
                            className="form-control"
                            onChange={(e) => {
                              const tmp = eventDates.map((event) => {
                                if (event.id === detail.id) {
                                  event.quantity = e.target.value
                                }
                                return event
                              })
                              setEventDates(tmp)
                            }}
                          />
                        ) : (
                          <div className="alert alert-danger">
                            {t('not_available')}
                          </div>
                        )}
                      </div>
                      <div
                        className="flex-fill ml-2 text-end"
                        style={{ textAlign: 'right' }}
                      >
                        {detail.quantity > 1
                          ? t('people')
                          : t('person')}
                      </div>
                    </div>
                    {quantityError && quantityError[detail.id] ? (
                      <InputError error={quantityError[detail.id]} />
                    ) : (
                      ''
                    )}
                  </div>
                )
              })}
            </div>
            {displayCheckout && (
              <div className="">
                <div className="mt-5 mb-5 alert alert-info">
                  {t('event_payment_confirmation', {
                    quantity: getTotal('quantity'),
                    total: getTotal('total'),
                    totalEuro: data.currencies.euro
                      ? (
                          getTotal('total') / data.currencies.euro
                        ).toFixed(2)
                      : 15,
                  })}
                </div>
                <GuestCheckout orderItems={[...orderItems]} />
              </div>
            )}
          </div>
        </div>
      )}
      {data?.online == 0 && (
        <div className="alert alert-danger">
          This event is not available right now!
        </div>
      )}
      <DonationFooter />
    </div>
  )
}
