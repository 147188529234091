import React, { useEffect, useState } from 'react'
import { useGet } from './Hooks/useGet'
import { useParams } from 'react-router-dom'
import Slider from './Slider'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Loader } from './Loader'

import { useTranslation } from 'react-i18next'
import { SkuItem } from './SkuItem'
import { GuestCheckout } from './GuestCheckout'
import { OrderItem } from './OrderItem'
import { DonationHeader } from './DonationHeader'
import { useDispatch } from 'react-redux'
import i18n from '../i18n'
import { setLanguage } from '../redux/languageSlice'
import { setEuro } from '../redux/currenciesSlice'
import { SocialShare } from './SocialShare'
import { GuestAddressBook } from './Helpers/GuestAddressBook'
import { ShareButton } from './Helpers/ShareButton'
export const ProductDetails = () => {
  const euro = useSelector((state) => state.currencies.euro)
  const lang = useSelector((state) => state.language.lang)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id } = useParams()
  let history = useHistory()
  const [displayShare, setDisplayShare] = useState(false)
  const url = 'product?id=' + id
  let total = 0
  const { data, error, isLoading } = useGet(url)
  const [orderItems, setOrderItems] = useState([])
  const [customFields, setCustomFields] = useState([])
  const changeLanguage = (lang) => {
    window.localStorage.setItem('lang', lang)
    i18n.changeLanguage(lang)
    dispatch(setLanguage(lang))
  }

  const handleAddToCard = (quantity, sku) => {
    const sellable = {
      sellableType: 'Sku',
      sellableId: sku.id,
      quantity: quantity,
      price: sku.price,
      label: `${data.name} ${sku.name}`,
      shippable: data.shippable,
      total: sku.price * quantity,
    }
    let updated = false
    console.log(orderItems)
    const tmp = [...orderItems]
    for (let i = 0; i < tmp.length; ++i) {
      if (tmp[i].sellableId === sellable.sellableId) {
        tmp[i].quantity = sellable.quantity
        tmp[i].price = sku.price
        tmp[i].total = sku.price * sellable.quantity
        updated = true
        break
      }
    }
    if (!updated) {
      tmp.push(sellable)
    }
    setOrderItems(tmp)

    // history.push('/cart')
  }

  const removeOrderItem = (item) => {
    const tmp = orderItems.filter(
      (i) => item.sellableId != i.sellableId,
    )
    setOrderItems(tmp)
  }

  const images = data
    ? data.images
        .filter((img) => img.lang === lang || img.lang === 'all')
        .map((image) => image.image)
    : []

  useEffect(() => {
    if (data?.currencies) {
      dispatch(setEuro(data.currencies.euro))
      document.title = data.name
    }
    if (data) {
      setCustomFields(data.custom_fields)
    }
  }, [data])

  if (error) {
    return <div className="alert alert-danger">{error}</div>
  } else {
    return (
      <div className="container">
        <DonationHeader onChangeLanguage={changeLanguage} />
        {isLoading && <Loader />}
        <br />
        <div className="row">
          <div className="col-lg-12">
            <h2 className="text-muted">{data && data.name}</h2>
          </div>
          <div className="col-md-7">
            <div className="card">
              <Slider images={images} />
              <ShareButton />
              <div
                className="card-body"
                dangerouslySetInnerHTML={{
                  __html: data?.information,
                }}
              ></div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card">
              <div className="card-header">{t('options')}</div>
              <p className="card-body">
                {data &&
                  data.skus.map((sku) => {
                    return (
                      <SkuItem
                        key={sku.id}
                        disabled={sku.quantity == 0}
                        sku={sku}
                        onAddToCardClick={handleAddToCard}
                      />
                    )
                  })}
              </p>
            </div>

            {orderItems.length > 0 && (
              <div>
                <div className="card">
                  <div className="card-header">
                    <i className="fa fa-shopping-basket"></i>{' '}
                    {t('about_to_buy')}
                  </div>
                  <div className=" card-body">
                    <table className="table align-items-center">
                      <thead>
                        <tr>
                          <td>{t('quantity')}</td>
                          <td>{t('option')}</td>
                          <td>{t('total')}</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {orderItems.map((item, index) => {
                          total += item.total
                          return (
                            <tr key={index}>
                              <td>{item.quantity}</td>
                              <td>{item.label}</td>
                              <td className="w-100">
                                {item.total} TL <br />({t('approx')} €
                                {(item.total / euro).toFixed(2)})
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => {
                                    removeOrderItem(item)
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={2} className="text-end">
                            {t('grand_total')}
                          </td>
                          <th colSpan={2}>
                            <b>{total} &#8378;</b> ({t('approx')} €
                            {(total / euro).toFixed(2)})
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <GuestCheckout
                  orderItems={[...orderItems]}
                  customFields={customFields}
                  shippable={data?.shippable}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
