import React, { useState } from 'react'
import { useGet } from './Hooks/useGet'
import configData from '../config/default.json'
import { News } from './News'
import { Pagination } from './Pagination'
import { Loader } from './Loader'

export const NewsList = () => {
    const [url, setUrl] = useState('news')
    const { data, isLoading, error } = useGet(url)
    const getNews = (data) => {
        let news = null

        if (error) {
            news = <div className="alert alert-danger">error</div>
        }
        if (data) {
            news = data.data.map((news) => {
                if (news.translations) {
                    return <News key={news.id} news={news}></News>
                }
            })
        }
        return news
    }

    return (
        <div className="container">
            {isLoading && <Loader />}
            {getNews(data)}
            <Pagination data={data} click={setUrl} />
        </div>
    )
}
