import React, { useState, useEffect } from 'react'
import api from './Api/Api'
import { getItem } from './Helpers/LocalStorage'
import { API_TOKEN } from '../config/default.json'
import { Loader } from './Loader'
import { InputError } from './InputError'
import { SuccessMessage } from './SuccessMessage'

export const PersonalDetails = () => {
    const [formData, setFormData] = useState({})
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState()
    const [success, setSuccess] = useState()

    const checkUser = async () => {
        try {
            const response = await api.get(
                'check/user?api_token=' + getItem(API_TOKEN),
            )

            setLoading(false)
            setFormData(response.data)
        } catch (ex) {
            setLoading(false)
        }
    }

    const handleOnChange = (e) => {
        let tmpData = {}
        const { name, value } = e.target
        tmpData[name] = value
        const a = { ...formData, ...tmpData }
        setFormData(a)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setErrors()
        setSuccess()
        try {
            const response = await api.post('user/update', formData)
            setLoading(false)
            setSuccess('Person details updated')
        } catch (ex) {
            setErrors(ex.response.data.errors)
            setLoading(false)
        }
    }

    useEffect(() => {
        checkUser()
        return () => {}
    }, [])
    return (
        <div>
            <div>
                <div className="heading">
                    <h3 className="text-uppercase">
                        Personal details
                    </h3>
                </div>
                {success && <SuccessMessage message={success} />}
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="row">
                        <div className="col-md-12">
                            {loading && <Loader />}
                            <div className="form-group">
                                <label htmlFor="firstname">
                                    Name Surname
                                </label>
                                <input
                                    value={formData && formData.name}
                                    onChange={(e) => {
                                        handleOnChange(e)
                                    }}
                                    id="firstname"
                                    type="text"
                                    name="name"
                                    className="form-control"
                                />
                            </div>
                            {errors && errors.name && (
                                <InputError error={errors.name} />
                            )}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="phone">
                                    Telephone
                                </label>
                                <input
                                    id="phone"
                                    name="phone"
                                    value={formData && formData.phone}
                                    onChange={(e) => {
                                        handleOnChange(e)
                                    }}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            {errors && errors.phone && (
                                <InputError error={errors.phone} />
                            )}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="email_account">
                                    Email
                                </label>
                                <input
                                    name="email"
                                    value={formData && formData.email}
                                    onChange={(e) => {
                                        handleOnChange(e)
                                    }}
                                    id="email_account"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            {errors && errors.email && (
                                <InputError error={errors.email} />
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="password">
                                    New Password
                                </label>
                                <input
                                    name="password"
                                    id="password"
                                    value={
                                        formData && formData.password
                                    }
                                    onChange={(e) => {
                                        handleOnChange(e)
                                    }}
                                    type="password"
                                    className="form-control"
                                />
                            </div>
                            {errors && errors.password && (
                                <InputError error={errors.password} />
                            )}
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="password">
                                    Confirm Password
                                </label>
                                <input
                                    name="password_confirmation"
                                    id="password"
                                    type="password"
                                    value={
                                        formData &&
                                        formData.password_confirmation
                                    }
                                    onChange={(e) => {
                                        handleOnChange(e)
                                    }}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="company">
                                    Company
                                </label>
                                <input
                                    name="company"
                                    value={
                                        formData && formData.company
                                    }
                                    onChange={(e) => {
                                        handleOnChange(e)
                                    }}
                                    id="company"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <button
                                type="submit"
                                className="btn btn-template-outlined"
                            >
                                <i className="fa fa-save"></i> Save
                                changes
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
