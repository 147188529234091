import React, { useState, useEffect } from 'react'
import api from './Api/Api'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import {
    removeShoppingCart,
    getOrderItems,
    updateShoppingCart,
    getItem,
} from './Helpers/LocalStorage'
import { updateOrderItem } from './Helpers/ManageCart'
import { useTranslation } from 'react-i18next'

export const OrderDetails = (props) => {
    const { t } = useTranslation()
    let history = useHistory()
    const deleteShoppingCart = props.deleteShoppingCart ? true : false
    if (deleteShoppingCart) {
        removeShoppingCart()
    }
    const { id } = useParams()
    const [order, setOrder] = useState({
        id: '',
        total_amount: '',
        created_at: '',
    })

    const getProductName = (lineItem) => {
        let productName
        switch (lineItem.sellable_type) {
            case 'App\\Models\\Product':
                productName = lineItem.sellable.name
                break
            case 'App\\Models\\EventDetail':
                productName = lineItem.sellable.title
                break
            case 'App\\Models\\Animal':
                productName = 'Donation for ' + lineItem.sellable.name
                break
            case 'App\\Models\\Donation':
                productName =
                    'Donation for ' + lineItem.sellable.title
                break
            case 'App\\Models\\Sku':
                productName =
                    lineItem.sellable.product.name +
                    ' ' +
                    lineItem.sellable.name
                break
            default:
                productName = ''
        }
        return productName
    }

    const getOrder = () => {
        const endpoint =
            'orders/show?id=' +
            id +
            '&api_token=' +
            getItem('api_token')
        api.get(endpoint).then((response) => {
            setOrder(response.data)
        })
    }
    useEffect(() => {
        getOrder()
        return () => {}
    }, [])
    const details = order.details
        ? order.details.map((lineItem) => {
              return (
                  <tr key={lineItem.id}>
                      <td>{getProductName(lineItem)}</td>
                      <td>{lineItem.quantity}</td>
                      <td>{lineItem.total / lineItem.quantity}</td>
                      <td>{lineItem.total}</td>
                  </tr>
              )
          })
        : []

    // const deleteOrder = () => {}

    const pay = () => {
        removeShoppingCart()

        order.details.forEach((detail) => {
            const orderItems = getOrderItems()
            const sellable = {
                orderId: order.id,
                sellableType: detail.sellable_type,
                sellableId: detail.id,
                quantity: detail.quantity,
                price: detail.total,
                label: getProductName(detail),
            }
            const updatedOrderItems = updateOrderItem(
                orderItems,
                sellable,
            )
            updateShoppingCart('orderItems', updatedOrderItems)
        })

        history.push('/checkout')
    }

    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            {t('order')} #{order.id}
                        </div>
                        <table className="table">
                            <tr>
                                <th>{t('date')}</th>
                                <td>
                                    {moment(order.created_at).format(
                                        'DD-MM-YYYY HH:mm',
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('total')}</th>
                                <td>{order.total_amount}</td>
                            </tr>
                            <tr>
                                <th>{t('status')}</th>
                                <td>
                                    <span
                                        className={
                                            order.status === 'new'
                                                ? 'badge badge-warning'
                                                : order.status ===
                                                  'completed'
                                                ? 'badge badge-success'
                                                : 'badge badge-danger'
                                        }
                                    >
                                        {order.status}
                                    </span>
                                </td>
                            </tr>

                            {order.auth_code ? (
                                <tr>
                                    <th>{t('authCode')}</th>
                                    <td>{order.auth_code}</td>
                                </tr>
                            ) : (
                                <tr>
                                    <th>
                                        {/* <button
                                            className="btn btn-danger"
                                            onClick={() => {
                                                deleteOrder()
                                            }}
                                        >
                                            Delete
                                        </button> */}
                                    </th>
                                    <td>
                                        <button
                                            className="btn btn-success"
                                            onClick={() => {
                                                pay()
                                            }}
                                        >
                                            Complete Order
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </table>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-header">
                            {t('details')}
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t('quantity')}</th>
                                    <th>{t('unitPrice')}</th>
                                    <th>{t('total')}</th>
                                </tr>
                            </thead>
                            <tbody>{details}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
