import React from 'react'
import { useTranslation } from 'react-i18next'
import '../css/donators.css'
export const Donators = (props) => {
  const { t } = useTranslation()
  const orderDetails = props.donators
  const tr = orderDetails.map((details) => {
    console.log(details)
    if (details.order.status === 'completed')
      return (
        <tr key={details.id}>
          <td>
            {details.display_in_donation_list == false
              ? 'Anonymous'
              : details.order.user.name}
          </td>
          <td> &#8378;{details.total} </td>
        </tr>
      )
  })
  return (
    <div>
      <h5>{t('donators')}</h5>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('amount')}</th>
          </tr>
        </thead>
        <tbody>{tr}</tbody>
      </table>
    </div>
  )
}
