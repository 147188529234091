import React from 'react'
import { useGet } from './Hooks/useGet'
import configData from '../config/default.json'
import { useParams } from 'react-router-dom'
import Slider from './Slider'
import { Adopt } from './Adopt'
import { Loader } from './Loader'

export const AnimalDetails = () => {
    const { id } = useParams()
    const path = 'animal?id=' + id
    const { data, error, isLoading } = useGet(path)
    if (error) {
        return <div className="alert alert-danger">{error}</div>
    } else if (isLoading) {
        return (
            <div className="container">
                <Loader />
            </div>
        )
    } else {
        return (
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-header">
                                {data?.name}
                            </div>

                            <Slider
                                images={
                                    data
                                        ? data.images?.map(
                                              (image) => {
                                                  return image.image
                                              },
                                          )
                                        : []
                                }
                            />
                            <div className="card-body">
                                {data?.translations[0].information}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <Adopt animal={data ? data : []} />
                    </div>
                </div>
            </div>
        )
    }
}
