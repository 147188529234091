import axios from 'axios'
import { serverURL } from '../Helpers/Config'
export default axios.create({
    baseURL: `${serverURL()}`,
    headers: {
        Authorization: `Bearer ${window.localStorage.getItem(
            'api_token',
        )}`,
        Accept: 'application/json',
        Language: window.localStorage.getItem('lang')
            ? window.localStorage.getItem('lang')
            : 'tr',
    },
})
