import React from 'react'

export const ChangePassword = () => {
    return (
        <div>
            <div>
                <div className="heading">
                    <h3 className="text-uppercase">
                        Change password
                    </h3>
                </div>
                <form>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="password_old">
                                    Old password
                                </label>
                                <input
                                    id="password_old"
                                    type="password"
                                    name="password"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="password_1">
                                    New password
                                </label>
                                <input
                                    id="password_1"
                                    type="new_password"
                                    name="confirm_password"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="password_2">
                                    Retype new password
                                </label>
                                <input
                                    id="password_2"
                                    type="password"
                                    name="confirm_new_password"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="btn btn-template-outlined"
                        >
                            <i className="fa fa-save"></i> Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
