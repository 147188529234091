import React from 'react'
import moment from 'moment'
import 'moment/locale/tr'
export const News = (props) => {
    const news = props.news

    return (
        <section className="post">
            <h2>{news && news.title}</h2>
            <div className="row">
                <div className="col-sm-6">
                    <p className="author-category">
                        {'By '}
                        <a href="#">
                            {news.user.name} {news.user.surname}
                        </a>
                    </p>
                </div>
                <div className="col-sm-6">
                    <p className="date-comments text-right">
                        <a href="#">
                            <i className="fa fa-calendar-o"></i>
                            {moment(news.created_at).format(
                                'DD-MM-YYYY HH:mm:ss',
                            )}
                        </a>
                    </p>
                </div>
            </div>
            <div className="image">
                <img
                    src={news.images[0]?.image}
                    className="img-fluid"
                    alt="news image"
                />
            </div>
            <div className="intro">
                <p style={{ 'margin-top': '10px', color: '#777' }}>
                    {news.information}
                </p>
            </div>
        </section>
    )
}
