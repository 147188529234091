import React from 'react'
import { render, hydrate } from 'react-dom'
import Root from './components/Root'
// import './css/bootstrap.css'
const rootElement = document.getElementById('root')
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
}
if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement)
} else {
  render(<Root />, rootElement)
}
export default Root
