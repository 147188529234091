import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { processPaymentUrl } from '../config/default.json'
import { Loader } from './Loader'
export const ProcessPayment = () => {
    const { t } = useTranslation()
    const { orderId } = useParams()
    const url = `${processPaymentUrl}${orderId}`
    return (
        <div className="container">
            <div className="card">
                <div className="card-header">
                    {t('processPayment')}
                </div>
                <div className="alert alert-warning">
                    Please follow the instuctions below if screen is
                    empty please wait until you get response from your
                    bank
                </div>
                <iframe
                    src={url}
                    id="iframe"
                    style={{
                        marginTop: '20px',
                        border: 'none',
                        width: '100%',
                        height: '500px',
                        position: 'relative',
                    }}
                ></iframe>
                <div className="card-footer">
                    {t('pleaseFollowInstructrionInBody')}
                </div>
            </div>
        </div>
    )
}
