import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { removeShoppingCart } from './Helpers/LocalStorage'

export const AccountNavigation = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div className="panel panel-default sidebar-menu">
                <div className="panel-heading">
                    <h3 className="h4 panel-title">
                        {t('accountNavigation')}
                    </h3>
                </div>
                <div className="panel-body">
                    <ul className="nav nav-pills flex-column text-sm">
                        <li className="nav-item">
                            <NavLink
                                to="/my-orders"
                                className="nav-link"
                            >
                                <i className="fa fa-list"></i>
                                {t('myOrders')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/personal-details"
                                className="nav-link"
                            >
                                <i className="fa fa-user"></i>{' '}
                                {t('personalDetails')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/address-books"
                                className="nav-link"
                            >
                                <i className="fa fa-address-card"></i>{' '}
                                {t('addressBook')}
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <a
                                href="#"
                                className="nav-link"
                                onClick={() => {
                                    localStorage.removeItem(
                                        'api_token',
                                    )
                                    removeShoppingCart()
                                    window.location.href = '/'
                                }}
                            >
                                <i className="fa fa-sign-out"></i>{' '}
                                {t('logout')}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
