import React from 'react'
import { AccountNavigation } from '../AccountNavigation'
import { Orders } from '../Orders'
import { Auth } from '../Auth'

export const Dashboard = () => {
    return (
        <div id="content">
            <Auth />
            <div className="container">
                <div className="row bar">
                    <div className="col-lg-9">
                        <Orders />
                    </div>

                    <div className="col-lg-3 mt-4 mt-lg-0">
                        <AccountNavigation />
                    </div>
                </div>
            </div>
        </div>
    )
}
