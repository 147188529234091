import { createSlice } from '@reduxjs/toolkit'

export const languageSlice = createSlice({
    name: 'language',
    initialState: {
        lang: 'tr',
    },
    reducers: {
        setLanguage: (state, action) => {
            state.lang = action.payload
        },
    },
})

export const { setLanguage } = languageSlice.actions
export default languageSlice.reducer