import React, { useState, useMemo } from 'react'
import { useEffect } from 'react'
import { imageURL } from './Helpers/Config'
let interval = null
const Slider = (props) => {
  const images = props.images
  const shuffle = (array) => {
    var currentIndex = array.length,
      randomIndex

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      // And swap it with the current element.
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ]
    }

    return array
  }
  // const images = useMemo(() => {
  //   return shuffle(props.images)
  // }, [props.images])
  const [index, setIndex] = useState(() => 0)

  const slideRight = () => {
    setIndex((prev) => (prev + 1) % images.length)
  }

  const handleSlideRight = () => {
    setIndex((prev) => (prev + 1) % images.length)
  }

  const handleSlideLeft = () => {
    setIndex((prev) => (prev - 1) % images.length)
  }

  const setImage = (index) => {
    setIndex(index)
  }

  const listItems = []

  images?.forEach((image, i) => {
    listItems.push(
      <li
        className={i === index ? 'bg-warning' : 'bg-info'}
        key={i}
        onClick={() => {
          setImage(i)
        }}
      ></li>,
    )
  })

  useEffect(() => {
    // let interval = null
    // if (images.length > 0) {
    //   interval = setInterval(slideRight, 5000)
    // }
    // return () => {
    //   clearInterval(interval)
    // }
  }, [images])

  return (
    images.length > 0 && (
      <div className="carousel slide" data-interval="false">
        <div className="carousel-inner text-center">
          <div className="item active">
            <img
              src={`${imageURL()}${images[index]}`}
              alt={index}
              className="img-fluid"
            />
          </div>
        </div>
        <div className="mt-2 d-flex align-items-center justify-content-between">
          <button
            className="btn fa fa-chevron-left float-start"
            onClick={handleSlideLeft}
          ></button>
          <ol className="carousel-indicators">{listItems}</ol>
          <button
            className="btn  fa fa-chevron-right"
            onClick={handleSlideRight}
          ></button>
        </div>
      </div>
    )
  )
}

export default Slider
