import React, { useEffect, useState } from 'react'
import { LoginRegister } from './LoginRegister'
import { API_TOKEN } from '../../config/default.json'
import { PlaceOrder } from '../PlaceOrder'
import api from '../Api/Api'
import { getItem } from '../Helpers/LocalStorage'
import { useDispatch, useSelector } from 'react-redux'
import { setLoggedIn } from '../../redux/loggedInSlice'
export const Checkout = () => {
    let dispatch = useDispatch()
    //const [loggedIn, setLoggedIn] = useState(true)
    const loggedIn = useSelector((state) => state.loggedIn)
    const style = 'color:#000;font-size:150%'
    const checkUser = async () => {
        try {
            const response = await api.get(
                'check/user?api_token=' + getItem(API_TOKEN),
            )
            dispatch(setLoggedIn(true))
        } catch (ex) {
            // if (ex.response.status === 401) {
            //     setLoggedIn(false)
            // }

            dispatch(setLoggedIn(false))
        }
    }
    useEffect(() => {
        checkUser()
        return () => {}
    }, [])
    return (
        <div>
            {loggedIn.loggedIn && <PlaceOrder />}
            {!loggedIn.loggedIn && <LoginRegister />}
        </div>
    )
}
