import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEn from './locales/en/translationEn.json'
import translationTr from './locales/tr/translationTr.json'

const resources = {
    en: {
        translation: translationEn,
    },
    tr: {
        translation: translationTr,
    },
}

i18n.use(initReactI18next).init({
    resources,
    lng: 'tr',
    keySeperator: false,
    interpollation: {
        escapeValue: false,
    },
})

export default i18n