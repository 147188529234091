import React from 'react'

export const Loader = () => {
    return (
        <div className="alert alert-info">
            <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
            ></span>
            <span className="col-lg-10">Loading...</span>
        </div>
    )
}
