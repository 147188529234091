import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export const Donation = (props) => {
    const { t, i18n } = useTranslation()
    const donation = props.donation
    let totalDonations = 0
    if (donation.donators) {
        donation.donators.forEach(
            (donator) => (totalDonations += donator.total),
        )
    }
    return (
        <div className={`mt-4 ${props.className}`}>
            <div className={`card`}>
                <div className="card-header ">{donation.title}</div>
                <img
                    src={donation.images[0].image}
                    className="img-fluit"
                />
                <div className="card-body">
                    {donation.information}
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-lg-6">
                            {totalDonations} / {donation.goal}TL
                        </div>
                        <div className="col-lg-6 text-right">
                            <Link
                                to={`/donation/${donation.id}`}
                                className="btn btn-blue"
                            >
                                {t('view')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
