import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { getAddressBookId, getItem } from './Helpers/LocalStorage'
import { Loader } from './Loader'
import api from './Api/Api'
import { API_TOKEN } from '../config/default.json'
import { ErrorTitle } from './ErrorTitle'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { setFailedPaymentURL } from '../redux/cwriSlice'
import { GuestAddressBook } from './Helpers/GuestAddressBook'
import { CustomFields } from './Helpers/CustomFields'

export const GuestCheckout = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const euro = useSelector((state) => state.currencies.euro)
  console.log(props)
  let history = useHistory()
  const [orderItems, setOrderItems] = useState([])
  const [customFields, setCustomFields] = useState(props.customFields)
  const months = [...Array(12).keys()]
  const [formData, setFormData] = useState({
    card_number: '',
    name_on_card: '',
    name: '',
    amount: props?.amount,
    cvc: '',
    expiration_month: '',
    expiration_year: '',
    api_token: getItem(API_TOKEN),
    address_book_id: getAddressBookId(),
    sku_id: props?.skuId,
    orderItems: '',
    pickup: 0,
    phone: 0,
    email: '',
    display_in_donation_list: true,
    shippable: props?.shippable,
    address: '',
    city: '',
    post_code: '',
    country_id: '',
  })

  const [loading, setLoading] = useState(false)
  const [nameError, setNameError] = useState('')
  const [customFieldsError, setCustomFieldsError] = useState('')
  const [cardError, setCardError] = useState('')
  const [cvcError, setCvcError] = useState('')
  const [expirationMonthError, setExpirationMonthError] = useState('')
  const [errors, setErrors] = useState()
  const [expirationYearError, setExpirationYearError] = useState('')
  const [addressErrors, setAddressErrors] = useState()
  const [phoneError, setPhoneError] = useState()
  const [emailError, setEmailError] = useState()

  const total = () => {
    let total = 0
    orderItems?.forEach((item) => {
      return (total += parseFloat(item.total))
    })

    return total
  }

  const monthOptions = months.map((month) => {
    month += 1
    let value = month
    if (month < 10) {
      value = `0${month}`
    }
    return (
      <option key={month} value={value}>
        {value}
      </option>
    )
  })

  const updateCustomFieldValues = (values) => {
    console.log('update custom field values on checkout')
    console.log(values)
    setFormData({ ...formData, custom_field_values: values })
  }

  const handleFormData = (e) => {
    console.log(
      'is array check',
      Array.isArray(props?.orderItems),
      props?.orderItems,
    )

    let tmpData = {
      ...formData,
      orderItems: JSON.stringify(orderItems),
    }
    console.log(tmpData)
    tmpData[e.target.name] = e.target.value
    tmpData[API_TOKEN] = getItem(API_TOKEN)
    setFormData(tmpData)
  }

  const handleSubmitPayment = async (e) => {
    e.preventDefault()
    dispatch(setFailedPaymentURL(window.location.href))
    setCardError('')
    setNameError('')
    setExpirationMonthError('')
    setExpirationYearError('')
    setErrors('')
    setCvcError()
    setPhoneError()
    setEmailError()
    setCustomFieldsError()
    console.log(formData)
    const data = new FormData()

    data.append('orderItems', JSON.stringify(orderItems))
    if (formData?.customFields) {
      Object.keys(formData?.custom_field_values).forEach((key) => {
        data.append(
          `custom_field_values[${key}]`,
          formData.custom_field_values[key],
        )
      })
    }

    Object.keys(formData).forEach((key) => {
      if ('custom_field_values' !== key && key != 'orderItems') {
        data.append(key, formData[key])
      }
    })

    console.log(...data)
    try {
      setLoading(true)

      const response = await api.post('pay', data)
      setLoading(false)
      history.push('/process-payment-volunteer/' + response.data.id)
    } catch (error) {
      setLoading(false)
      const errorResponse = error.response
      setErrors(errorResponse)
      if (errorResponse.status === 422) {
        const errors = errorResponse.data.errors
        if (errors.card_number) {
          setCardError(errors.card_number)
        }
        if (errors.name_on_card) {
          setNameError(errors.name_on_card)
        }
        if (errors.expiration_month) {
          setExpirationMonthError(errors.expiration_month)
        }
        if (errors.expiration_year) {
          setExpirationYearError(errors.expiration_year)
        }
        if (errors.cvc) {
          setCvcError(errors.cvc)
        }
        if (errors.expiration_month) {
          setExpirationMonthError(errors.expiration_month)
        }
        const errorAddress = {}
        if (errors.address) {
          errorAddress.address = errors.address
        }
        if (errors.city) {
          errorAddress.city = errors.city
        }
        if (errors.post_code) {
          errorAddress.post_code = errors.post_code
        }
        if (errors.country) {
          errorAddress.country = errors.country
        }
        if (errors.phone) {
          setPhoneError(errors.phone)
        }
        if (errors.email) {
          setEmailError(errors.email)
        }
        if (errors.custom_field_values) {
          setCustomFieldsError(errors.custom_field_values)
        }
        setAddressErrors(errorAddress)
      }
    }
  }

  const yearOptions = []
  const currentYear = moment().year()
  for (let year = currentYear; year <= currentYear + 10; ++year) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>,
    )
  }

  useEffect(() => {
    if (!Array.isArray(props.orderItems)) {
      setOrderItems([props.orderItems])
    } else {
      setOrderItems(props.orderItems)
    }
  }, [props.orderItems])

  return (
    <form onSubmit={handleSubmitPayment}>
      <div className="mt-2 mb-2">
        <CustomFields
          customFields={customFields}
          updateCustomFieldValues={updateCustomFieldValues}
          errors={customFieldsError}
        />
      </div>
      {props?.shippable === 1 && (
        <GuestAddressBook
          handleFormData={handleFormData}
          errors={addressErrors}
        />
      )}

      <div className="card">
        <div className="card-header">
          {t('processPayment')}
          <img
            src="/img/visa.png"
            height={32}
            className="float-right"
          />
        </div>
        <div className="card-body">
          {loading && <Loader />}
          {errors && <ErrorTitle errors={errors} />}

          <div className="">
            <label htmlFor="name_on_card">{t('name_on_card')}</label>
            <input
              type="text"
              className="form-control"
              name="name_on_card"
              id="name_on_card"
              onChange={handleFormData}
            />
            {nameError && (
              <div className="alert alert-danger">{nameError}</div>
            )}
          </div>

          <div className="mt-3">
            <label htmlFor="card_number">{t('card_number')}</label>
            <input
              type="text"
              className="form-control"
              name="card_number"
              id="card_number"
              onChange={handleFormData}
            />
            {cardError && (
              <div className="alert alert-danger">{cardError}</div>
            )}
          </div>

          <div className="d-flex mt-2">
            <div className=" mr-2">
              <label htmlFor="phone">{t('phone')}</label>
              <input
                type="phone"
                name="phone"
                id="phone"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    phone: parseInt(e.target.value),
                  })
                }
                className="form-control"
              />
              {phoneError && (
                <div className="alert alert-danger">{phoneError}</div>
              )}
            </div>
            <div className="flex-fill ml-2">
              <label htmlFor="email">{t('email')}</label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  })
                }
                className="form-control"
              />
              {emailError && (
                <div className="alert alert-danger">{emailError}</div>
              )}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-6 ">
              <div className=" d-flex">
                <label className="d-flex justify-content-start align-items-center">
                  {t('expiry_date')}
                </label>
                <div className="form-group w-50 ml-2">
                  <select
                    id="expiry_month"
                    className="form-control"
                    name="expiration_month"
                    onChange={handleFormData}
                  >
                    <option value="">{t('mm')}</option>
                    {monthOptions}
                  </select>
                  {expirationMonthError && (
                    <div className="alert alert-danger">
                      {expirationMonthError}
                    </div>
                  )}
                </div>
                <div className="form-group w-50 ml-2">
                  <select
                    id="expiry_year"
                    className="form-control"
                    name="expiration_year"
                    onChange={handleFormData}
                  >
                    <option value="">{t('yy')}</option>
                    {yearOptions}
                  </select>
                  {expirationYearError && (
                    <div className="alert alert-danger">
                      {expirationYearError}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-4 ">
              <div className="d-flex">
                <label
                  htmlFor="cvc"
                  className="justify-content-start d-flex align-items-center mr-3"
                >
                  CVC
                </label>
                <div className="justify-content-end d-flex">
                  <input
                    type="text"
                    name="cvc"
                    id="cvc"
                    className="form-control"
                    onChange={handleFormData}
                  />
                </div>
              </div>
              {cvcError && (
                <div className="d-block">
                  <div className="alert alert-danger">{cvcError}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card-footer d-flex align-items-center justify-content-between">
          <div>
            {props?.showAnonymousCheckbox && (
              <>
                <input
                  id="anonymous"
                  type="checkbox"
                  onChange={(e) => {
                    console.log(!e.target.checked)
                    setFormData({
                      ...formData,
                      display_in_donation_list: !e.target.checked,
                    })
                  }}
                />
                <label htmlFor="anonymous" className="ml-2">
                  {t('anonymus_payments')}
                </label>
              </>
            )}
          </div>
          <button
            className="btn btn-success "
            disabled={total() === 0}
            onClick={handleSubmitPayment}
          >
            {t('submit')}
          </button>
        </div>
      </div>
    </form>
  )
}
