import React from 'react'
import { Modal } from 'react-bootstrap'

export const YesNoModal = (props) => {
    return (
        <div>
            <Modal show={props.modalState} onHide={props.modalState}>
                <Modal.Header>Delete {props.name}?</Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete {props.name}?
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            props.setModalState(false)
                            props.action(props.id)
                        }}
                    >
                        Delete
                    </button>
                    <button
                        className="btn btn-success"
                        onClick={() => {
                            props.setModalState(false)
                        }}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
