import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGet } from './Hooks/useGet'
import { PaymentVolunteer } from './PaymentVolunteer'

export const CwriPayment = () => {
  const params = useParams()
  const [euro, setEuro] = useState(() => 15.5)
  const { data, error, isLoading } = useGet('currency')
  useEffect(() => {
    if (data) {
      console.log('data updated')
      console.log(data)
      setEuro(data.euro)
    }
  }, [isLoading])
  console.log(euro)
  console.log(isLoading)
  const skuId = 529
  return (
    <div className="container ">
      <div className="text-center">
        <img src="/img/cwri-payment-logo.jpg" className="img-fluid" />

        <h4 className="card-header text-center container w-85">
          Cyprus Wildlife Research Institute Volunteering Payment Form
        </h4>
      </div>
      <div className="container w-75">
        <table className="table table-striped mt-2 text-large">
          <tbody>
            <tr>
              <th style={{ fontSize: '140%' }}>Volunteer Name</th>
              <td
                style={{
                  fontSize: '140%',
                  textTransform: 'capitalize',
                }}
              >
                {params.name}
              </td>
            </tr>
            <tr>
              <th style={{ fontSize: '140%' }}>Payment Amount</th>
              <td
                style={{
                  fontSize: '140%',
                  textTransform: 'capitalize',
                }}
              >
                {params.amount} TL (
                {(params.amount / euro).toFixed(2)} &euro;)
              </td>
            </tr>
          </tbody>
        </table>
        <div className="m-2 mt-4">
          For online credit card payment, please fill out your name,
          exact payment amount and credit card details to submit your
          payment. Upon successful completion of your payment, you
          will get a final confirmation for placement in the Project.
        </div>
        <div className="mt-2 flex">
          <div className="card-header font-weight-bold d-flex">
            Credit Card Payment Form{' '}
            <div className="d-flex">
              <img
                className="img-fluid ml-3"
                src="/img/visa.png"
                style={{
                  width: '100px',
                  marginTop: '-5px',
                  position: 'absolute',
                }}
              />
            </div>
          </div>
          <PaymentVolunteer skuId={skuId} euro={euro} />
        </div>
        <div className="mt-2 border-top border-bottom">
          You can alternatively send payment through paypal using this
          link:
          <a
            href="https://paypal.me/NaturePark"
            className="p-2"
            target="blank"
          >
            https://paypal.me/NaturePark{' '}
            <img
              src="/img/paypal.png"
              className="img-fluid"
              style={{ marginTop: '-6px' }}
            />
          </a>
        </div>
        <div className="mt-3 pb-3 border-bottom">
          You can alternatively send payment through{' '}
          <strong>Wise</strong> to kbasat@gmail.com
          <img
            src="/img/wise-logo.png"
            className="img-fluid"
            style={{
              marginTop: '-6px',
              width: '8%',
              marginLeft: '10px',
            }}
          />
        </div>
        <div className="mt-3">
          <div>
            If you like to send payment using wire transfer, here are
            the details
            <img
              style={{ width: '5%' }}
              src="/img/bank_transfer.png"
              className="img-fluid ml-3"
            />
          </div>
          <table className="table table-striped mt-3">
            <tbody>
              <tr>
                <th>BIC/SWIFT CODE:</th>
                <td>ISBKTRISXXX</td>
              </tr>
              <tr>
                <th>Bank Name</th>
                <td>Turkiye Is Bankasi / Gonyeli Branch</td>
              </tr>
              <tr>
                <th>IBAN</th>
                <td>TR820006400000268040084716 – EUR Account</td>
              </tr>
              <tr>
                <th>Name</th>
                <td>Taskent Doga Parki</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>Taskent Doga Parkı, Taskent, Girne, Cyprus</td>
              </tr>
            </tbody>
          </table>
          <div className="text-center"></div>
        </div>
      </div>
      <div className="card-footer text-center">
        Copyright Cyprus Wildlife Research Institute &copy;2022
      </div>
    </div>
  )
}
