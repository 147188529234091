import React, { useState, useEffect } from 'react'
import api from './Api/Api'
import { Loader } from './Loader'
import { API_TOKEN } from '../config/default.json'
// import { getItem } from './Helpers/LocalStorage'
// import { useHistory } from 'react-router-dom'

export const Login = (props) => {
    const redirect = props.redirect ? props.redirect : '/dashboard'
    // let history = useHistory()
    const [authMessage, setAuthMessage] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(() => {
        return {
            email: '',
            password: '',
        }
    })

    const handleChange = (e) => {
        let tmpData = data
        tmpData[e.target.name] = e.target.value
        setData(tmpData)
    }

    const handleLogin = async () => {
        setLoading(true)
        setEmailError('')
        setPasswordError('')
        setAuthMessage('')
        try {
            const response = await api.post('login', data)
            setLoading(false)
            window.localStorage.setItem(
                API_TOKEN,
                response.data.api_token,
            )
            //history.push(redirect ? redirect : '/dashboard')
            window.location.href = redirect ? redirect : '/dashboard'
        } catch (error) {
            const errorResponse = error.response
            if (errorResponse.status === 422) {
                if (errorResponse.data.errors.email) {
                    setEmailError(errorResponse.data.errors.email[0])
                }
                if (errorResponse.data.errors.password) {
                    setPasswordError(
                        errorResponse.data.errors.password[0],
                    )
                }
            }
            setLoading(false)
            setAuthMessage(errorResponse.data.message)
        }
    }

    useEffect(() => {
        //checkLoggedIn()
        return () => {}
    }, [])

    return (
        <div className="box">
            <h2 className="text-uppercase">Login</h2>
            <p className="lead">Already our customer?</p>
            <hr />
            {loading && <Loader />}
            {authMessage && (
                <div className="alert alert-danger">
                    {authMessage}
                </div>
            )}
            <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                    id="email"
                    name="email"
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                />
                {emailError && (
                    <div className="alert alert-danger">
                        {emailError}
                    </div>
                )}
            </div>
            <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                    id="password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    className="form-control"
                />
                {passwordError && (
                    <div className="alert alert-danger">
                        {passwordError}
                    </div>
                )}
            </div>
            <div className="text-center">
                <button
                    type="submit"
                    onClick={handleLogin}
                    className="btn btn-template-outlined"
                >
                    <i className="fa fa-sign-in"></i> Log in
                </button>
            </div>
        </div>
    )
}
