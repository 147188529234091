import React from 'react'

export const PaginationItem = (props) => {
    const link = props.link
    const style = {
        backgroundColor: '#6aae7a',
        color: '#fff',
        fontWeight: 'bold',
    }
    return (
        <li className={'page-item '}>
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault()
                    window.scrollTo(0, 0)
                    props.setLink(link.url)
                }}
                className="page-link "
                style={link.active ? style : {}}
                dangerouslySetInnerHTML={{ __html: link.label }}
            ></a>
        </li>
    )
}
