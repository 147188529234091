import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ShareButton } from './Helpers/ShareButton'
import Slider from './Slider'

export const SingleEvent = (props) => {
  const { t, i18n } = useTranslation()
  const event = props.event
  const lang = useSelector((state) => state.language.lang)
  return (
    <div>
      <div className={`mt-4 ${props.className}`}>
        <div className={`card`}>
          <Slider
            images={
              event
                ? event?.images
                    .filter((image) => {
                      return (
                        image.lang === lang || image.lang === 'all'
                      )
                    })
                    .map((img) => img.image)
                : []
            }
          />
          <div className="mt-2">
            <ShareButton />
          </div>
          <div
            className="card-body"
            dangerouslySetInnerHTML={{
              __html: event?.information,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}
