import React, { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import configData from '../config/default.json'
import { useGet } from './Hooks/useGet'
import { NavLink } from 'react-router-dom'
export const ProductFilter = () => {
    const { t } = useTranslation()
    const productCategories = useGet('product_categories')

    const [filtered, setFiltered] = useState(false)

    const [cls, setCls] = useState('badge badge-primary mr-2')
    const [categories, setCategories] = useState()
    useEffect(() => {
        if (productCategories.data) {
            const cats = productCategories.data.map((cat) => {
                return (
                    <NavLink
                        to={`/product/category/${cat.id}`}
                        key={cat.id}
                        className={cls}
                        activeClassName="badge badge-warning"
                        onClick={() => {
                            setFiltered(true)
                        }}
                    >
                        {cat.name}
                    </NavLink>
                )
            })
            setCategories(cats)
        }
        return () => {}
    }, [productCategories.data])
    return (
        <div>
            <div className="mt-4 mb-4">
                <h1 className="h6">{t('filter')}</h1>
                {categories}
                {filtered && (
                    <NavLink
                        to="/products"
                        className="badge badge-secondary"
                        onClick={() => setFiltered(false)}
                    >
                        {t('remove_filter')}
                    </NavLink>
                )}
            </div>
        </div>
    )
}
