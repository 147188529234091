import React, { useState, useEffect } from 'react'
import api from './Api/Api'
import { Link, useHistory } from 'react-router-dom'
import { Loader } from './Loader'
import { getItem } from './Helpers/LocalStorage'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export const Orders = () => {
    const { t } = useTranslation()
    let history = useHistory()
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(true)
    const getOrders = () => {
        setLoading(true)
        const endpoint = 'orders?api_token=' + getItem('api_token')
        api.get(endpoint)
            .then((response) => {
                setOrders(response.data)
                setLoading(false)
            })
            .catch((ex) => {
                const errorResponse = ex.response
                if (errorResponse.status === 401) {
                    history.push('/login')
                }
                setLoading(false)
            })
    }

    useEffect(() => {
        getOrders()
        return () => {}
    }, [])

    const getOrderStatusBadge = (order) => {
        if (order.status === 'new') {
            return 'badge badge-warning'
        }
        if (order.status === 'completed') {
            return 'badge badge-success'
        }
        if (order.status === 'failed') {
            return 'badge badge-danger'
        }
    }

    const tbody = orders.map((order) => {
        return (
            <tr key={order.id}>
                <td>{order.id}</td>
                <td>
                    {moment(order.created_at).format('D-MM-Y HH:MM')}
                </td>
                <td>{order.total_amount}</td>
                <td>
                    <span className={getOrderStatusBadge(order)}>
                        {t(order.status)}
                    </span>
                </td>
                <td>
                    <Link
                        to={`orders/${order.id}`}
                        className="btn btn-blue"
                    >
                        {t('details')}
                    </Link>
                </td>
            </tr>
        )
    })

    return (
        <div className="table-responsive">
            {loading && <Loader />}
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>{t('orders')}</th>
                        <th>{t('date')}</th>
                        <th>{t('total')}</th>
                        <th>{t('status')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{tbody}</tbody>
            </table>
        </div>
    )
}
