import React, { useState } from 'react'
import { useGet } from './Hooks/useGet'
import configData from '../config/default.json'
import { Pagination } from './Pagination'
import { SingleEvent } from './SingleEvent'
import { Loader } from './Loader'
export const Events = () => {
    const [url, setUrl] = useState('events')
    const { data, isLoading, error } = useGet(url)
    const getEvents = (data) => {
        let events = null

        if (error) {
            events = <div className="alert alert-danger"></div>
        }
        if (data) {
            events = data.data.map((event) => {
                return (
                    <SingleEvent
                        className="col-md-4"
                        key={event.id}
                        event={event}
                    ></SingleEvent>
                )
            })
        }
        return events
    }

    return (
        <div className="container">
            {isLoading && <Loader />}
            <div className="row">{getEvents(data)}</div>
            <Pagination data={data} click={setUrl} />
        </div>
    )
}
