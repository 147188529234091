import { createSlice } from '@reduxjs/toolkit'

export const loggedInSlice = createSlice({
    name: 'loggedIn',
    initialState: {
        loggedIn: false,
    },
    reducers: {
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload
        },
    },
})

export const { setLoggedIn } = loggedInSlice.actions
export default loggedInSlice.reducer