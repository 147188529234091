import React from 'react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { SocialShare } from '../SocialShare'

export const ShareButton = () => {
  const [displayShare, setDisplayShare] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <SocialShare
        onShow={displayShare}
        onHide={() => {
          setDisplayShare(false)
        }}
      />
      <button
        className="btn btn-share"
        onClick={() => setDisplayShare((prev) => !prev)}
      >
        {t('share')}
      </button>
    </>
  )
}
