import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { serverURL } from '../Helpers/Config'

export const useGet = (path) => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)

    const language = useSelector((state) => state.language)
    if (path && !path.includes('http')) {
        path = serverURL() + path
    }

    useEffect(async () => {
        const cancelToken = axios.CancelToken
        const source = cancelToken.source()

        try {
            const response = await axios.get(path, {
                cancelToken: source.token,
                headers: {
                    Language: language.lang,
                },
            })
            setData(response.data)
            setIsLoading(false)
            setError(null)
        } catch (err) {
            console.log(err)
            if (axios.isCancel(err)) {
                console.log('aborting request')
            } else {
                console.error(err)
                setIsLoading(false)
                setError(err.message)
                console.log(err.message)
            }
        }

        return () => {
            console.log('return use fetch anonimus')
            source.cancel('axios request canceled')
        }
    }, [path, language])

    return {
        data,
        isLoading,
        error,
    }
}
