import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
    getAddressBookId,
    getItem,
    getOrderItems,
} from './Helpers/LocalStorage'
import { Loader } from './Loader'
import api from './Api/Api'
import { API_TOKEN } from '../config/default.json'
import { ErrorTitle } from './ErrorTitle'
import { useHistory } from 'react-router-dom'
import { AddressBookSelect } from './AddressBookSelect'

export const Payment = () => {
    let history = useHistory()
    const months = [...Array(12).keys()]
    const [formData, setFormData] = useState({
        orderItems: getOrderItems(),
        card_number: '',
        name_on_card: '',
        cvc: '',
        expiration_month: '',
        expiration_year: '',
        api_token: getItem(API_TOKEN),
        address_book_id: getAddressBookId(),
    })
    const [loading, setLoading] = useState(false)
    const [nameError, setNameError] = useState('')
    const [cardError, setCardError] = useState('')
    const [cvcError, setCvcError] = useState('')
    const [addressBookError, setAddressBookError] = useState('')
    const [expirationMonthError, setExpirationMonthError] = useState(
        '',
    )
    const [errors, setErrors] = useState()
    const [expirationYearError, setExpirationYearError] = useState('')
    const [shippable, setShippable] = useState()

    const monthOptions = months.map((month) => {
        month += 1
        let value = month
        if (month < 10) {
            value = `0${month}`
        }
        return (
            <option key={month} value={value}>
                {value}
            </option>
        )
    })

    useEffect(() => {
        setShippable(
            getOrderItems().some((item) => {
                return item.shippable === 1
            }),
        )
        return () => {}
    }, [])

    const handleFormData = (e) => {
        let tmpData = formData
        tmpData[e.target.name] = e.target.value
        tmpData[API_TOKEN] = getItem(API_TOKEN)
        tmpData['address_book_id'] = getAddressBookId()
        setFormData(tmpData)
    }

    const handleSubmitPayment = async (e) => {
        e.preventDefault()
        setCardError('')
        setAddressBookError('')
        setNameError('')
        setExpirationMonthError('')
        setExpirationYearError('')
        setErrors('')
        setCvcError()
        const addressBookId = getAddressBookId()

        if (
            (!addressBookId || addressBookId.trim() === '') &&
            shippable
        ) {
            setAddressBookError('please select address')
            return
        }
        try {
            setLoading(true)
            const response = await api.post('pay', formData)
            setLoading(false)
            history.push('/process-payment/' + response.data.id)
        } catch (error) {
            setLoading(false)
            const errorResponse = error.response
            setErrors(errorResponse)
            if (errorResponse.status === 422) {
                const errors = errorResponse.data.errors
                if (errors.card_number) {
                    setCardError(errors.card_number)
                }
                if (errors.name_on_card) {
                    setNameError(errors.name_on_card)
                }
                if (errors.expiration_month) {
                    setExpirationMonthError(errors.expiration_month)
                }
                if (errors.expiration_year) {
                    setExpirationYearError(errors.expiration_year)
                }
                if (errors.cvc) {
                    setCvcError(errors.cvc)
                }
                if (errors.expiration_month) {
                    setExpirationMonthError(errors.expiration_month)
                }
            }
        }
    }

    const yearOptions = []
    const currentYear = moment().year()
    for (let year = currentYear; year <= currentYear + 10; ++year) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>,
        )
    }
    return (
        <form onSubmit={handleSubmitPayment}>
            <div className="card">
                <div className="card-header">Payment Details</div>
                <div className="card-body">
                    {loading && <Loader />}
                    {errors && <ErrorTitle errors={errors} />}

                    {shippable && <AddressBookSelect />}
                    {addressBookError && (
                        <div className="alert alert-danger">
                            {addressBookError}
                        </div>
                    )}
                    <div className="form-group">
                        <label htmlFor="name_on_card">
                            Name on cart
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="name_on_card"
                            id="name_on_cart"
                            onChange={handleFormData}
                        />
                        {nameError && (
                            <div className="alert alert-danger">
                                {nameError}
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="cart_number">
                            Cart Number
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="card_number"
                            id="card_number"
                            onChange={handleFormData}
                        />
                        {cardError && (
                            <div className="alert alert-danger">
                                {cardError}
                            </div>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-lg-2">Expiry Date</div>
                        <div className="col-lg-2">
                            <div className="form-group">
                                <select
                                    id="expiry_month"
                                    className="form-control"
                                    name="expiration_month"
                                    onChange={handleFormData}
                                >
                                    <option value="">MM</option>
                                    {monthOptions}
                                </select>
                                {expirationMonthError && (
                                    <div className="alert alert-danger">
                                        {expirationMonthError}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="form-group">
                                <select
                                    id="expiry_year"
                                    className="form-control"
                                    name="expiration_year"
                                    onChange={handleFormData}
                                >
                                    <option value="">YY</option>
                                    {yearOptions}
                                </select>
                                {expirationYearError && (
                                    <div className="alert alert-danger">
                                        {expirationYearError}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-2">CVC</div>
                        <div className="col-lg-4">
                            <input
                                type="text"
                                name="cvc"
                                className="form-control"
                                onChange={handleFormData}
                            />
                            {cvcError && (
                                <div className="alert alert-danger">
                                    {cvcError}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="card-footer text-right">
                    <button
                        className="btn btn-blue"
                        onClick={handleSubmitPayment}
                    >
                        Continue Payment
                    </button>
                </div>
            </div>
        </form>
    )
}
