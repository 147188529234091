import React from 'react'
import { useState, useEffect } from 'react'
import '../css/donation-buttons.css'
import { useTranslation } from 'react-i18next'
import { useRef } from 'react'

export const DonationButtons = (props) => {
  const [amountList, setAmountList] = useState(props.amountList)
  const [amount, setAmount] = useState(0)
  const { t } = useTranslation()
  const [selected, setSelected] = useState()
  const otherAmountTextBox = useRef()
  const [otherSelected, setOtherSelected] = useState(false)
  useEffect(() => {
    if (otherSelected) {
      otherAmountTextBox?.current?.focus()
    }
  }, [otherSelected])
  useEffect(() => {
    setSelected(props.selected)
  }, [props.selected])
  useEffect(() => {
    setAmountList(props.amountList)
  }, [props.amountList])
  const element = (
    <div className="buttonContainer">
      {amountList?.map((amnt) => {
        const classes = ['buttons']
        if (selected == amnt) {
          classes.push('amountSelected')
        }
        return (
          <button
            key={amnt}
            onClick={() => {
              setAmount(amnt)
              setOtherSelected(false)
              props.updateAmount(amnt)
              setSelected(amnt)
            }}
            className={classes.join(' ')}
          >
            {/* &#8378;{amnt} */}
            {amnt}TL
          </button>
        )
      })}
      {props.otherAmount ? (
        <div
          className={
            otherSelected ? 'buttons amountSelected' : 'buttons'
          }
          onClick={() => {
            setSelected(0)
            setOtherSelected(true)
            otherAmountTextBox?.current?.focus()
          }}
        >
          {otherSelected && (
            <div>
              <span className="tl"></span>
              <input
                type="text"
                ref={otherAmountTextBox}
                onChange={(e) => props.updateAmount(e.target.value)}
                className="otherAmountText"
              />
            </div>
          )}
          {otherSelected == false && t('other_amount')}
        </div>
      ) : (
        ''
      )}
    </div>
  )
  return element
}
