import { SHOPPING_CART } from '../../config/default.json'

export const setItem = (key, value) => {
    if (typeof value === 'object') {
        value = JSON.stringify(value)
    }
    window.localStorage.setItem(key, value)
}

export const getItem = (key) => {
    const value = window.localStorage.getItem(key)
    try {
        return JSON.parse(value)
    } catch (ex) {
        return value
    }
}

export const removeItem = (key) => {
    window.localStorage.removeItem(key)
}

export const updateShoppingCart = (key, value) => {
    let shoppingCart = getItem(SHOPPING_CART)
    if (!shoppingCart) {
        shoppingCart = {}
    }
    shoppingCart[key] = value
    setItem(SHOPPING_CART, shoppingCart)
}

export const getOrderItems = () => {
    let shoppingCart = getItem(SHOPPING_CART)
    if (!shoppingCart || !shoppingCart.orderItems) {
        shoppingCart = {
            orderItems: [],
        }
        setItem(SHOPPING_CART, shoppingCart)
    }
    return shoppingCart.orderItems
}

export const removeShoppingCart = () => {
    setItem(SHOPPING_CART, {})
}

export const getAddressBookId = () => {
    const shoppingCart = getItem(SHOPPING_CART)
    if (shoppingCart && shoppingCart.addressBookId) {
        return shoppingCart.addressBookId + ''
    }
    return ''
}